export enum Dimension {
	Pixels = 'pixels',
	Inches = 'inches',
	Centimeters = 'centimeters',
	Millimeters = 'millimeters',
	Points = 'points',
	Picas = 'picas'
}

export const ScaleTable: Record<Dimension, Record<Dimension, number>> = {
	[Dimension.Pixels]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 1,
		[Dimension.Centimeters]: 1,
		[Dimension.Millimeters]: 1,
		[Dimension.Points]: 1,
		[Dimension.Picas]: 1
	},
	[Dimension.Inches]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 1,
		[Dimension.Centimeters]: 2.54,
		[Dimension.Millimeters]: 25.4,
		[Dimension.Points]: 72,
		[Dimension.Picas]: 6
	},
	[Dimension.Centimeters]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 0.393701,
		[Dimension.Centimeters]: 1,
		[Dimension.Millimeters]: 10,
		[Dimension.Points]: 28.3465,
		[Dimension.Picas]: 2.3622
	},
	[Dimension.Millimeters]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 0.0393701,
		[Dimension.Centimeters]: 0.1,
		[Dimension.Millimeters]: 1,
		[Dimension.Points]: 2.83465,
		[Dimension.Picas]: 0.23622
	},
	[Dimension.Points]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 0.0138889,
		[Dimension.Centimeters]: 0.0352778,
		[Dimension.Millimeters]: 0.352778,
		[Dimension.Points]: 1,
		[Dimension.Picas]: 0.0833333
	},
	[Dimension.Picas]: {
		[Dimension.Pixels]: 1,
		[Dimension.Inches]: 0.166667,
		[Dimension.Centimeters]: 0.422667,
		[Dimension.Millimeters]: 4.22675,
		[Dimension.Points]: 12,
		[Dimension.Picas]: 1
	}
};

export class Dimensions {
	public static convert(
		valueW: number,
		valueH: number,
		from: Dimension,
		to: Dimension,
		originalScale: number = 300
	) {
		if(from === to) {
			return { width: valueW, height: valueH, originalScale };
		}

		if(!originalScale) {
			originalScale = 300;
		}

		const scalingFactor = this.getSacalingFactor(from, to);
		let pixelW = valueW;
		let pixelH = valueH;
		if(from !== Dimension.Pixels) {
			pixelW = valueW * originalScale;
			pixelH = valueH * originalScale;
			// console.log('Converting back to pixels', pixelW, pixelH);
		}

		// console.log('From:', from, 'To:', to, 'Scaling Factor:', scalingFactor, 'Resoulution:', originalScale)

		if(to === Dimension.Pixels) {
			return {
				width: Math.round(pixelW),
				height: Math.round(pixelH)
			};
		}

		return {
			width: (pixelW / originalScale) * scalingFactor,
			height: (pixelH / originalScale) * scalingFactor,
			originalScale: originalScale / scalingFactor
		};
	}

	public static getSacalingFactor(from: Dimension, to: Dimension) {
		if(from === to) {
			return 1;
		}

		return ScaleTable[from]?.[to] ?? 1;
	}
}
