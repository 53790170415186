import { IsArray, IsBoolean, IsEnum, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { AnimationConfigDto } from '../animation.dto';
import {
	CustomizationDto,
	CustomizationGroupDto,
	CustomizationImagePickerDto,
	CustomizationItemDto,
	CustomizationSelectDto,
	CustomizationTextDto,
	CustomizationToggleDto,
	CustomizationType,
	CustomizationTypeArray,
	CustomizationVideoPickerDto
} from '../customization/customization.dto';
import { ThemeDto } from '../theme.dto';

import { LayerDto } from '../layer/layer.dto';
import { StylesConfigDto } from '../styles.dto';
import { PrintConfigDto } from '../print.dto';
import { AssetDto } from '../../../asset/models/asset.dto';
import { LocaleDto } from '../locale.dto';

export enum CreativeUnitDimensionsUnit {
	PIXELS = 'px',
	INCHES = 'in',
	CENTIMETERS = 'cm',
	MILLIMETERS = 'mm',
	POINTS = 'pt',
	PICAS = 'pc'
}

export class CreativeUnitDimensionsDto {
	@IsNumber()
	width: number;

	@IsNumber()
	height: number;

	@IsOptional()
	@IsNumber()
	ppi?: number; // Pixels per inch.  Used for print render paths.

	@IsOptional()
	@IsEnum(CreativeUnitDimensionsUnit)
	defaultDisplayUnit?: CreativeUnitDimensionsUnit; // Default display unit for the dimensions.  Defaults to px.

	@IsOptional()
	@Type(() => CreativeUnitDimensionsDto)
	originalDimensions?: CreativeUnitDimensionsDto;

	@IsOptional()
	@IsArray()
	@IsOptional()
	@IsString({ each: true })
	canTransformInto?: string[]; // Other units this one can transform into.
}

export class CreativeUnitDto {
	@IsString()
	id: string;

	@IsString()
	name: string;

	@IsOptional()
	@IsString()
	description?: string;

	@IsString()
	packageId: string;

	@IsOptional()
	@IsString()
	creativeUnitId?: string; // If this creative unit is a clone of another, this is the ID of the original.

	@IsOptional()
	@IsString()
	containerClasses?: string;

	@IsOptional()
	@ValidateNested({ each: true })
	@IsArray()
	@Type(() => LayerDto)
	layers: LayerDto[];

	@ValidateNested()
	@IsObject()
	@Type(() => CreativeUnitDimensionsDto)
	dimensions: CreativeUnitDimensionsDto;

	@IsOptional()
	styles?: any | StylesConfigDto[];

	@IsOptional()
	@ValidateNested()
	@Type(() => AssetDto)
	thumbnail?: AssetDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => ThemeDto)
	theme?: ThemeDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => AnimationConfigDto)
	animationConfig?: AnimationConfigDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => PrintConfigDto)
	printConfig?: PrintConfigDto;

	@IsOptional()
	@IsArray()
	customizations?: any;

	@IsOptional()
	@IsObject()
	variables?: any;

	@IsOptional()
	@IsObject()
	variableOverrides?: any; // Overrides for variables set in the creative unit.

	@IsOptional()
	@IsBoolean()
	isDefault?: boolean;

	@IsOptional()
	@IsBoolean()
	hidden?: boolean;

	@IsOptional()
	@IsString()
	templateCreativeUnit?: string;

	@IsOptional()
	locale?: LocaleDto;
}

export class CreativeUnitWithCustomizationsDto extends CreativeUnitDto {
	@ValidateNested({ each: true })
	@IsArray()
	@IsOptional()
	@Type(() => CustomizationDto, {
		keepDiscriminatorProperty: true,
		discriminator: {
			property: 'type',
			subTypes: CustomizationTypeArray
		}
	})
	customizations?: CustomizationItemDto[];

	@IsOptional()
	@IsObject()
	variables?: { [key: string]: any };
}
