import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Entity } from '../../../_core/state/entities.model';
import { AssetDto } from '../../../../../../api/src/asset/models/asset.dto';
import { ImagePickerActionsDto } from '../../../../../../api/src/creative-unit-package/models/customization/customization.dto';
import { ImageActionType } from '../../../../../../api/src/asset/models/image-action.dto';

@Component({
	selector: 'app-image-editor-sidebar',
	templateUrl: './image-editor-sidebar.component.html',
	styleUrls: ['./image-editor-sidebar.component.scss']
})
export class ImageEditorSidebarComponent implements OnInit, OnChanges {
	@Input() activeAsset: AssetDto;
	@Input() config: ImagePickerActionsDto;
	@Output() setActiveAsset: EventEmitter<AssetDto> = new EventEmitter<AssetDto>();
	@Output() setActiveVariations: EventEmitter<Partial<AssetDto>[]> = new EventEmitter<Partial<AssetDto>[]>();
	@Output() setActiveToolOption: EventEmitter<Entity> = new EventEmitter<Entity>();
	@Output() setConfig: EventEmitter<ImagePickerActionsDto> = new EventEmitter<ImagePickerActionsDto>();

	public toolOptions: Entity[] = [
		{
			id: 'background',
			name: 'Background',
			tools: [ImageActionType.BackgroundRemoval, ImageActionType.ReplaceBackground, ImageActionType.BlurBackground]
		},
		{
			id: 'text-to-image',
			name: 'Text to Image',
			tools: [ImageActionType.TextToImage]
		},
		{
			id: 'target-crop',
			name: 'Focal Area Crop',
			tools: [ImageActionType.TargetCrop]
		}
	];
	filteredToolOptions: Entity[] = [];
	activeToolOption: Entity;

	constructor() {}

	ngOnInit(): void {
		this.filteredToolOptions = [...this.toolOptions];

		if (this.config?.showActions?.length) {
			// Filter the tool options tools array to only include the tools that are in the config.
			this.filteredToolOptions = this.filteredToolOptions.map(option => {
				option.tools = option.tools.filter(tool => this.config.showActions.includes(tool));
				return option;
			});
		}

		if (this.config?.hideActions?.length) {
			// Filter the tool options tools array to exclude the tools that are in the config.
			this.filteredToolOptions = this.filteredToolOptions.map(option => {
				option.tools = option.tools.filter(tool => !this.config.hideActions.includes(tool));
				return option;
			});
		}

		// Remove any tool options that have no tools.
		this.filteredToolOptions = this.filteredToolOptions.filter(option => option.tools.length > 0);

		if (!this.activeAsset) {
			this.activeToolOption =
				this.filteredToolOptions.find(option => option.id === ImageActionType.TextToImage) || this.toolOptions[0];
		} else {
			this.activeToolOption = this.filteredToolOptions[0];
		}

		this.setActiveToolOption.emit(this.activeToolOption);
	}

	ngOnChanges(changes: SimpleChanges): void {}

	setActiveTool(tool: Entity) {
		this.activeToolOption = tool;
		this.setActiveToolOption.emit(tool);
	}

	setConfigValue(config: Partial<ImagePickerActionsDto>) {
		this.setConfig.emit({
			...this.config,
			...config
		});
	}
}
