import { resolveDotNotationPath } from './object.utils';

export function stringToSlug(str: string) {
	if (!str) return str;

	str = str.replace(/^\s+|\s+$/g, ''); // trim
	str = str.toLowerCase();

	// remove accents, swap ñ for n, etc
	const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
	const to = 'aaaaeeeeiiiioooouuuunc------';
	for (let i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}

	str = str
		.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
		.replace(/\s+/g, '-') // collapse whitespace and replace by -
		.replace(/-+/g, '-'); // collapse dashes

	return str;
}

/**
 * Looks through a string and replaces any merge tags using a ${} syntax with variables.
 * Example: A ${option.color} fox jumps over a ${option.size} dog.
 * @param str
 * @param obj
 */
export function replaceMergeTags(str: string, obj: any) {
	// Replace ${} with evaluated variable paths
	str = str?.replace(/\${(.*?)}/g, match => {
		return resolveDotNotationPath(match.replace('${', '').replace('}', ''), obj);
	});

	if (typeof str === 'string') {
		// Replace any remaining ${} with empty string
		str = str.replace(/\${(.*?)}/g, '');
	} else {
		str = '';
	}

	return str;
}

/**
 * Convert a string to a safe name for filename
 */
export function stringToFilename(str: string) {
	//generate random string
	if (!str) {
		return Math.random().toString(36).substring(2, 15); // generate random name
	}

	return str.replace(/[^a-z0-9\-]/gi, '_') // replace non-alphanumeric characters with underscores
		.replace(/_{2,}/g, '_') // remove duplicate underscores
		.replace(/^_+|_+$/g, ''); // remove trailing underscores
}

export function capitalizeString(str: string) {
	if (!str) {
		return str;
	}

	return str.charAt(0).toUpperCase() + str.slice(1);
}
