import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { materialModules } from '../../../angular-material.module';
import { BaseInputComponent } from './base-input/base-input.component';
import { GroupInputComponent } from './group-input/group-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { InputComponent } from './input/input.component';
import { ToggleInputComponent } from './toggle-input/toggle-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { JsonInputComponent } from './json-input/json-input.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { CodemirrorInputComponent } from './codemirror-input/codemirror-input.component';
import { MultiselectInputComponent } from './multiselect-input/multiselect-input.component';
import { RequireSelectionDirective } from './multiselect-input/require-selection.directive';
import { RangeInputComponent } from './range-input/range-input.component';
import { MultiAddInputComponent } from './multi-add-input/multi-add-input.component';
import {SharedDirectiveModule} from "../../directives/shared-directive.module";
import { SetLocaleEditButtonComponent } from './set-locale-edit-button/set-locale-edit-button.component';

@NgModule({
	declarations: [
		BaseInputComponent,
		GroupInputComponent,
		SelectInputComponent,
		TextInputComponent,
 		InputComponent,
   		ToggleInputComponent,
		TextareaInputComponent,
 		JsonInputComponent,
   		CodemirrorInputComponent,
     	MultiselectInputComponent,
	 	RequireSelectionDirective,
   		RangeInputComponent,
     	MultiAddInputComponent,
		SetLocaleEditButtonComponent
	],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CodemirrorModule,
		SharedDirectiveModule,
        ...materialModules
    ],
	providers: [],
	exports: [
		GroupInputComponent,
		SelectInputComponent,
		TextInputComponent,
		ToggleInputComponent,
		InputComponent,
		TextareaInputComponent,
		JsonInputComponent,
		CodemirrorInputComponent,
		MultiselectInputComponent,
		RangeInputComponent,
		MultiAddInputComponent,
		SetLocaleEditButtonComponent
	]
})
export class InputModule {}
