import { Type } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';

export enum ConditionRelation {
	GT = 'gt', // >
	GTE = 'gte', // >=
	LT = 'lt', // <
	LTE = 'lte', // <=
	EQ = 'eq', // ===
	NEQ = 'neq', // !==
	CONTAINS = 'contains',
	INCLUDES = 'includes',
	EXCLUDES = 'excludes',
	UNDEFINED = 'undefined',
	NOT_UNDEFINED = 'not-undefined'
}

export class VisibilityConditionDto {
	@IsOptional()
	@IsString()
	path: string;

	@IsOptional()
	@IsNotEmpty()
	value: string | number;

	@IsOptional()
	@IsEnum(ConditionRelation)
	relation: ConditionRelation;

	@IsOptional()
	@IsString()
	type?: string;

	@IsOptional()
	@ValidateNested({ each: true })
	@Type(() => VisibilityConditionDto)
	conditions?: VisibilityConditionDto[];
}
