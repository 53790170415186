import { Component, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { BaseInputComponent } from '../base-input/base-input.component';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'app-multi-add-input',
	templateUrl: './multi-add-input.component.html',
	styleUrls: ['./multi-add-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiAddInputComponent extends BaseInputComponent implements OnInit, OnChanges {
	@Input() addLabel: string = 'Add New Row';
	@Input() keyLabel: string = 'Key';
	@Input() keyProperty: string = 'key';
	@Input() valueLabel: string = 'Value';
	@Input() valueProperty: string = 'value';
	@Input() noDataMessage: string = 'No data';
	@Input() showNoDataMessage: boolean = false;
	@Input() showHeader: boolean = true;
	@Input() showAddButton: boolean = true;
	@Input() showRemoveButton: boolean = true;
	@Input() lockKey: boolean = false;
	@Input() keyOptions: {'label': string, value: 'string'}[];
	@Input() dataType: 'array' | 'object' = 'array';

	public skipChangeDetection: boolean = false;
	public changeSubscription: any;

	constructor(private fb: FormBuilder) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		// this.initializeForm();
		this.onFormValueChanges();
	}

	ngOnChanges(changes: SimpleChanges): void {
		// super.ngOnChanges(changes);
		if (changes.currentValue && !changes.currentValue.firstChange) {
			// console.log('MultiAddInputComponent.ngOnChanges', changes.currentValue)
			if (this.skipChangeDetection) {
				// console.log('MultiAddInputComponent.ngOnChanges', 'skipChangeDetection', this.skipChangeDetection)
				this.skipChangeDetection = false;
				return;
			}

			if (this.changeSubscription) {
				this.changeSubscription.unsubscribe();
			}

			this.initializeForm();

			this.onFormValueChanges();
		}
	}

	initializeForm(currentValue?: any, validators?: ValidatorFn | ValidatorFn[]) {

		if (!this.form) {
			this.form = this.fb.group({
				items: this.fb.array([])
			});
		}

		let value = currentValue || this.currentValue || this.parentForm?.get(this.controlName).value;
		// console.log('MultiAddInputComponent.initializeForm', value, this.dataType, this.keyProperty, this.valueProperty);

		if (value) {
			if (Array.isArray(value)) {
				this.setArrayInput(value);

				if (!value.length) {
					this.addNewRow();
				}
			} else if (typeof value === 'object' && value !== null) {
				this.setObjectInput(value);

				if (!Object.keys(value).length) {
					this.addNewRow();
				}
			}
		} else {
			this.addNewRow();
		}
	}

	handleCurrentValueChange(value: any): void {
		this.initializeForm();
	}

	addNewRow(): void {
		const items = this.form.get('items') as FormArray;
		items.push(this.fb.group({
			key: new FormControl('', Validators.required),
			value: new FormControl('', Validators.required)
		}));
		this.emitDataChange();
	}

	removeRow(index: number): void {
		const items = this.form.get('items') as FormArray;
		items.removeAt(index);
		this.emitDataChange();
	}

	setArrayInput(array: any[]): void {
		const items = this.form.get('items') as FormArray;
		items.clear();
		array.forEach(item => {
			items.push(this.fb.group({
				key: new FormControl(item[this.keyProperty], Validators.required),
				value: new FormControl(item[this.valueProperty], Validators.required)
			}));
		});
	}

	setObjectInput(object: any): void {
		const items = this.form.get('items') as FormArray;
		items.clear();
		Object.keys(object).forEach(key => {
			items.push(this.fb.group({
				key: new FormControl(key, Validators.required),
				value: new FormControl(object[key], Validators.required)
			}));
		});

		// If lockKey is true, disable the key input.
		if (this.lockKey) {
			items.controls.forEach(control => {
				control.get('key').disable();
			});
		}
	}

	get items(): FormArray {
		return this.form.get('items') as FormArray;
	}

	onFormValueChanges(): void {
		this.changeSubscription = this.form.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(() => {
			this.emitDataChange();
		});
	}

	emitValueChange(): void {
		// Do nothing.
	}

	emitDataChange(): void {
		let output;
		// Use getRawValue() instead of value to include disabled controls
		const itemsRawValue = this.items.getRawValue();

		if (Array.isArray(this.currentValue) || this.dataType === 'array') {
			output = itemsRawValue.map(item => ({ [this.keyProperty]: item.key, [this.valueProperty]: item.value }));
		} else {
			output = {};
			itemsRawValue.forEach(item => {
				output[item.key] = item.value;
			});
		}

		if (this.parentForm) {
			this.parentForm.get(this.controlName).setValue(output);
		}

		// Turn this on to skip the change detection.
		this.skipChangeDetection = true;

		this.valueChange.emit(output);
	}
}
