export enum FieldType {
	Group = 'group',
	Text = 'text',
	Checkbox = 'checkbox',
	Select = 'select',
	Hidden = 'hidden',
	ReCaptcha = 'reCaptcha',
	Email = 'email',
	Phone = 'phone',
	State = 'state',
	Date = 'date',
	File = 'file'
}