import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsObject, IsOptional, IsString, ValidateNested } from "class-validator";
import { Type } from "class-transformer";

export enum FilterType {
	Search = 'search',
	Toggle = 'toggle',
	SingleSelect = 'single-select',
	MultiSelect = 'multi-select',
	Autocomplete = 'autocomplete',
	DateRange = 'date-range'
}

export enum FilterLayout {
	Horizontal = 'horizontal',
	Vertical = 'vertical'
}

export class ExtraFilterOption {
	@IsOptional()
	@IsString()
	maskPath?: string; // Used when needing to replace labels per organization

	[key: string]: any;
}

/**
 * A filter option is a value that represents a certain kind of data for that filter.
 * Usually used as options for a select component or autocomplete for chips inputs.
 */
export class FilterOption {
	@IsOptional()
	@IsString()
	id?: string;

	@IsString()
	@IsNotEmpty()
	name: string;

	@IsOptional()
	value?: any;

	@IsOptional()
	@IsString()
	entityName?: string; // Used if you need to map back to the entity types coming from API

	@IsOptional()
	@IsObject()
	@ValidateNested()
	@Type(() => ExtraFilterOption)
	extra?: ExtraFilterOption;
}

/**
 * Filter Model
 * A filter is an item that represents a user defined selection that should limit returned results for a view.
 * These filter items are mainly used today on the overview pages (Plan / Activate) to limit the results of each of the 'Views' in those pages.
 * However, this model can be used for any filter item in the system.
 *
 * Filters are currently created inside of the `*.model.ts` files for entities that show up in the overview pages.
 */
export class FilterDef {
	@IsString()
	id: string;

	@IsString()
	name: string;

	@IsString()
	category: string;

	@IsString()
	paramKey: string; // Used to match the form key to update values

	@IsString()
	@IsEnum(FilterType)
	type: FilterType; // Designates what type of input to use

	@IsOptional()
	@IsEnum(FilterLayout)
	layout?: FilterLayout;  // Should this show up in the sidebar or the toolbar?  Defaults to horizontal

	@IsOptional()
	@IsBoolean()
	grow?: boolean; // Filter should grow to fill the space

	@IsOptional()
	@IsBoolean()
	topPadding?: boolean; // Removes the border on the top of the filter elements

	@IsOptional()
	@IsString()
	prefixIcon?: string;

	@IsOptional()
	@IsArray()
	@ValidateNested({ each: true })
	@Type(() => FilterOption)
	options?: FilterOption[];  // Use if you have static options to pass into the filter

	@IsOptional()
	@IsString()
	globalSettingsOptionsKey?: string; //  Use this if you want to pull options from the global settings.

	@IsOptional()
	@ValidateNested()
	@Type(() => FilterOption)
	value?: FilterOption | FilterOption[];

	@IsOptional()
	@IsString()
	entityName?: string; // Used if you need to map back to the entity types coming from API

	@IsOptional()
	@IsString()
	matchEndpoint?: string; // Used to make sure this filter category matches the main entity that is loaded in.

	@IsOptional()
	@IsNumber()
	order?: number;

	@IsOptional()
	@IsObject()
	@ValidateNested()
	@Type(() => ExtraFilterOption)
	extra?: ExtraFilterOption;
}
