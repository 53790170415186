import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { materialModules } from '../angular-material.module';
import { AddUserDialogComponent } from './components/add-user-dialog/add-user-dialog.component';
import { AssetManagerDialogComponent } from './components/asset-manager-dialog/asset-manager-dialog.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { ButtonSelectComponent } from './components/button-select/button-select.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DelinquentBusinessDialogComponent } from './components/delinquent-business-dialog/delinquent-business-dialog.component';
import { SearchArrayByPipe } from './pipes/search-array-by.pipe';
import { DraggablePlayheadDirective } from './directives/draggable-playhead.directive';
import { VideoPlayerDialogComponent } from './components/video-player-dialog/video-player-dialog.component';
import { DisclaimerDialogComponent } from './components/disclaimer-dialog/disclaimer-dialog.component';
import { DragResizeDirective } from './directives/drag-resize.directive';
import { ZoomPanComponent } from './components/zoom-pan/zoom-pan.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { AccountBarComponent } from './components/header/account-bar/account-bar.component';
import { EditorNavigationComponent } from './components/header/editor-navigation/editor-navigation.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoComponent } from './components/header/logo/logo.component';
import { ProjectsNavigationComponent } from './components/header/projects-navigation/projects-navigation.component';
import { VersionSelectorComponent } from './components/header/version-selector/version-selector.component';
import { ProjectDetailsDialogComponent } from './components/project-details-dialog/project-details-dialog.component';
import { SelectDialogComponent } from './components/select-dialog/select-dialog.component';
import { VersionNumberComponent } from './components/version-number/version-number.component';
import { NewsPopupDialogComponent } from './components/news-popup-dialog/news-popup-dialog.component';
import { AssetMediaComponent } from './components/asset-media/asset-media.component';
import { DraggableSegmentDirective } from './directives/draggable-segment.directive';
import { DropFileDirective } from './directives/drop-file.directive';
import { LocalVarDirective } from './directives/local-var.directive';
import { ResizeColumnDirective } from './directives/resize-column.directive';
import { ArrayOrDefaultPipe } from './pipes/array-or-default.pipe';
import { ColonToDotPipe } from './pipes/colon-to-dot.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { FilterArrayByPipe } from './pipes/filter-array-by.pipe';
import { MarkDownPipe } from './pipes/markdown.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ThumbnailPipe } from './pipes/thumbnail.pipe';
import { OrderByAlphaPipe } from './pipes/orderByAlpha.pipe';
import { AddExternalPaymentMethodDialogComponent } from './components/add-external-payment-method-dialog/add-external-payment-method-dialog.component';
import { DataImportDialogComponent } from './components/data-import-dialog/data-import-dialog.component';
import { FileSizeFormatPipe } from './pipes/file-size-format.pipe';
import { CurrencyEnvPipe } from './pipes/currency-env.pipe';
import { BusinessTrialAlertComponent } from './components/business-trial-alert/business-trial-alert.component';
import { OnboardPartsDialogComponent } from './components/onboard-parts-dialog/onboard-parts-dialog.component';
import { ObjectValuesPipe } from './pipes/object-values.pipe';
import { ClipTagsPipe } from './pipes/clip-tags.pipe';
import { TransformObjectPipe } from './pipes/transform-object.pipe';
import { ProjectPosterPipe } from './pipes/project-poster.pipe';
import { AssetTemplateSelectorComponent } from './components/asset-template-selector/asset-template-selector.component';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { AssetTemplateSelectorDialogComponent } from './components/asset-template-selector-dialog/asset-template-selector-dialog.component';
import { popperVariation, TippyModule, tooltipVariation } from '@ngneat/helipopper';
import { TextSingularPluralPipe } from './pipes/text-singular-plural.pipe';
import { ResizableColumnDirective } from './directives/resizable-column.directive';
import { MapPipe } from './pipes/map.pipe';
import { ClickProgressDirective } from './directives/click-progress.directive';
import { AssetExportComponent } from './components/asset-export/asset-export.component';
import { AssetExportDialogComponent } from './components/asset-export-dialog/asset-export-dialog.component';
import { ExportSetFormatsComponent } from './components/asset-export/set-formats/export-set-formats.component';
import { ExportSelectPackagesComponent } from './components/asset-export/select-packages/export-select-packages.component';
import { FlattenLayersPipe } from './pipes/merge-group-layers.pipe';
import { FilterByVisibilityConditionsPipe } from './pipes/filter-by-visibility-condition.pipe';
import { ImageEditorDialogComponent } from './image-editor-dialog/image-editor-dialog.component';
import { ImageEditorSidebarComponent } from './image-editor-dialog/image-editor-sidebar/image-editor-sidebar.component';
import { ImageEditorVariationTrayComponent } from './image-editor-dialog/image-editor-variation-tray/image-editor-variation-tray.component';
import { InputModule } from './components/input/input.module';
import { ImageToolBackgroundComponent } from './image-editor-dialog/tools/image-tool-background/image-tool-background.component';
import { ImageToolImageGenerationComponent } from './image-editor-dialog/tools/image-tool-image-generation/image-tool-image-generation.component';
import { CloudinaryVideoTransformPipe } from './pipes/cloudinary-video-transform.pipe';
import { CloudinaryImageTransformPipe } from './pipes/cloudinary-image-transform.pipe';
import { ArrayFilterByPropertyExistsPipe } from './pipes/array-filter-by-propery-exists.pipe';
import { ExportDownloadComponent } from './components/export-download/export-download.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StringifyPipe } from './pipes/stringify.pipe';
import { EllipsisTooltipDirective } from './directives/ellipsis-tooltip.directive';
import { TabNavigationComponent } from './components/tab-navigation/tab-navigation.component';
import { PickFromArrayPipe } from './pipes/pick-from-array.pipe';
import { DisablePointerEventsDirective } from './directives/disable-pointer-events.directive';
import { QueryAnimationDialogComponent } from './components/query-animation-dialog/query-animation-dialog.component';
import { DimensionPipe } from './pipes/dimension.pipe';
import { RenameModalComponent } from './components/tab-navigation/rename-modal/rename-modal.component';
import { SuperAdminPipe } from './pipes/super-admin.pipe';
import { SharedDirectiveModule } from './directives/shared-directive.module';
import { DisableExportVideoPackagePipe } from './components/asset-export/pipes/disable-export-video-package.pipe';
import { OutdatedBrowserAlertComponent } from "./components/outdated-browser-alert/outdated-browser-alert.component";
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPaginatorIntl } from '../_core/services/localization.service';
import { LanguageSelectorComponent } from './components/header/language-selector/language-selector.component';
import { ExportConfigOptionComponent } from './components/asset-export/config-option/export-config-option.component';
import { SetLocaleDialogComponent } from './locale/set-locale-dialog/set-locale-dialog.component';
import { SetLocaleEditButtonComponent } from './components/input/set-locale-edit-button/set-locale-edit-button.component';
import { GetLocalePipe } from './pipes/get-locale.pipe';
import {
	ProjectItemThumbnailCardHoverComponent
} from "./components/project-item-thumbnail-card-hover/project-item-thumbnail-card-hover.component";
import { AnchorSelectedTab } from "./directives/anchor-selected-tab.directive";
import { ImageCropperModule } from './components/image-cropper/image-cropper.module';
import { ImageToolCropComponent } from './image-editor-dialog/tools/image-tool-crop/image-tool-crop.component';
import { ItemReviewStatusComponent } from "./components/item-review-status/item-review-status.component";
import { ProjectListingsThumbnailsPipe } from "./pipes/project-listings-thumbnails.pipe";
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { exceededSupportedWidthAlertComponent } from "./components/exceeded-supported-width-browser-alert/exceeded-supported-width-alert.component";
import { DisableExportUnapprovedGroupPipe } from "./components/asset-export/pipes/disable-export-unapproved-group.pipe";
import { DisableExportUnapprovedItemsPipe } from "./components/asset-export/pipes/disable-export-unapproved-items.pipe";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        InputModule,
        TippyModule,
		ImageCropperModule,
        ReactiveFormsModule,
        ...materialModules,
        TippyModule.forRoot({
            defaultVariation: 'tooltip',
            variations: {
                tooltip: tooltipVariation,
                popper: popperVariation
            }
        }),
        MatProgressBarModule,
        SharedDirectiveModule,
        ItemReviewStatusComponent,
        ProjectListingsThumbnailsPipe
    ],
	declarations: [
		VersionNumberComponent,
		ColonToDotPipe,
		HeaderComponent,
		LanguageSelectorComponent,
		LogoComponent,
		AccountBarComponent,
		EditorNavigationComponent,
		ProjectsNavigationComponent,
		DurationPipe,
		ThumbnailPipe,
		VersionSelectorComponent,
		DraggableSegmentDirective,
		DraggablePlayheadDirective,
		DropFileDirective,
		SelectDialogComponent,
		ProjectDetailsDialogComponent,
		AddUserDialogComponent,
		AssetManagerDialogComponent,
		FileUploaderComponent,
		LocalVarDirective,
		ConfirmDialogComponent,
		DelinquentBusinessDialogComponent,
		VideoPlayerDialogComponent,
		SafeHtmlPipe,
		OrderByAlphaPipe,
		MarkDownPipe,
		AutocompleteComponent,
		ButtonSelectComponent,
		NewsPopupDialogComponent,
		AssetMediaComponent,
		OnboardPartsDialogComponent,
		ResizeColumnDirective,
		FilterArrayByPipe,
		SearchArrayByPipe,
		ArrayOrDefaultPipe,
		DisclaimerDialogComponent,
		DragResizeDirective,
		ZoomPanComponent,
		TransformObjectPipe,
		AddExternalPaymentMethodDialogComponent,
		DataImportDialogComponent,
		FileSizeFormatPipe,
		ProjectPosterPipe,
		ResizableColumnDirective,
		AssetTemplateSelectorComponent,
		AssetTemplateSelectorDialogComponent,
		AssetExportComponent,
		AssetExportDialogComponent,
		FilterArrayPipe,
		TextSingularPluralPipe,
		CurrencyEnvPipe,
		BusinessTrialAlertComponent,
		ObjectValuesPipe,
		ClipTagsPipe,
		MapPipe,
		ClickProgressDirective,
		FlattenLayersPipe,
		FilterByVisibilityConditionsPipe,
		ImageEditorDialogComponent,
		ImageEditorSidebarComponent,
		ImageEditorVariationTrayComponent,
		ImageToolBackgroundComponent,
		ImageToolImageGenerationComponent,
		ImageToolCropComponent,
		FilterByVisibilityConditionsPipe,
		CloudinaryVideoTransformPipe,
		CloudinaryImageTransformPipe,
		ExportSetFormatsComponent,
		ExportConfigOptionComponent,
		ExportSelectPackagesComponent,
		ArrayFilterByPropertyExistsPipe,
		ExportDownloadComponent,
		StringifyPipe,
		TabNavigationComponent,
		PickFromArrayPipe,
		DisablePointerEventsDirective,
		QueryAnimationDialogComponent,
		DimensionPipe,
		RenameModalComponent,
		SuperAdminPipe,
		DisableExportVideoPackagePipe,
		DisableExportUnapprovedGroupPipe,
		DisableExportUnapprovedItemsPipe,
		OutdatedBrowserAlertComponent,
		SetLocaleDialogComponent,
		GetLocalePipe,
		LocalizedDatePipe,
		ProjectItemThumbnailCardHoverComponent,
		AnchorSelectedTab,
		exceededSupportedWidthAlertComponent
	],
	providers: [
		DatePipe,
		CurrencyEnvPipe,
		ProjectPosterPipe,
		ObjectValuesPipe,
		DisableExportVideoPackagePipe,
		DisableExportUnapprovedGroupPipe,
		{ provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
	],
	exports: [
		InputModule,
		CommonModule,
		VersionNumberComponent,
		ColonToDotPipe,
		HeaderComponent,
		LogoComponent,
		AccountBarComponent,
		EditorNavigationComponent,
		ProjectsNavigationComponent,
		DurationPipe,
		ThumbnailPipe,
		DraggableSegmentDirective,
		DraggablePlayheadDirective,
		LocalVarDirective,
		DropFileDirective,
		AssetManagerDialogComponent,
		VersionSelectorComponent,
		FileUploaderComponent,
		VideoPlayerDialogComponent,
		SafeHtmlPipe,
		OrderByAlphaPipe,
		MarkDownPipe,
		AutocompleteComponent,
		ButtonSelectComponent,
		NewsPopupDialogComponent,
		AssetMediaComponent,
		OnboardPartsDialogComponent,
		ResizeColumnDirective,
		FilterArrayByPipe,
		SearchArrayByPipe,
		ArrayOrDefaultPipe,
		DisclaimerDialogComponent,
		ZoomPanComponent,
		TransformObjectPipe,
		DataImportDialogComponent,
		FileSizeFormatPipe,
		ProjectPosterPipe,
		AssetTemplateSelectorComponent,
		AssetTemplateSelectorDialogComponent,
		AssetExportComponent,
		AssetExportDialogComponent,
		FilterArrayPipe,
		CurrencyEnvPipe,
		BusinessTrialAlertComponent,
		ObjectValuesPipe,
		ClipTagsPipe,
		TextSingularPluralPipe,
		ResizableColumnDirective,
		MapPipe,
		ClickProgressDirective,
		FlattenLayersPipe,
		FilterByVisibilityConditionsPipe,
		ImageEditorDialogComponent,
		FilterByVisibilityConditionsPipe,
		CloudinaryVideoTransformPipe,
		CloudinaryImageTransformPipe,
		ArrayFilterByPropertyExistsPipe,
		ExportDownloadComponent,
		StringifyPipe,
		TabNavigationComponent,
		PickFromArrayPipe,
		QueryAnimationDialogComponent,
		DimensionPipe,
		SuperAdminPipe,
		EllipsisTooltipDirective,
		LanguageSelectorComponent,
		SetLocaleDialogComponent,
		GetLocalePipe,
		AnchorSelectedTab,
		ImageToolCropComponent,
		LocalizedDatePipe,
		AnchorSelectedTab,
		exceededSupportedWidthAlertComponent,
		OutdatedBrowserAlertComponent,
		DisableExportVideoPackagePipe,
		DisableExportUnapprovedGroupPipe,
		DisableExportUnapprovedItemsPipe,
	]
})
export class SharedModule {}
