import {IsEnum, IsNotEmpty, IsOptional, IsString} from 'class-validator';
import {CartItemType} from '../project-cart-item.entity';
import { LocaleDto } from '../../creative-unit-package/models/locale.dto';

export class ProjectCartItemDto {

	@IsOptional()
	@IsString()
	name?: string;

	@IsOptional()
	@IsEnum(CartItemType)
	type?: CartItemType;

	@IsOptional()
	parentId?: string;

	@IsNotEmpty()
	itemData: any;

	@IsOptional()
	metadata?: Record<string, any>;

	@IsOptional()
	dirty?: boolean;

	@IsOptional()
	locale?: LocaleDto;

}
