import { IsArray, IsBoolean, IsEnum, IsNumberString, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { VisibilityConditionDto } from '../visibility-condition.dto';
import { CustomizationOptionDto, CustomizationOptionMappingDto } from './customization-option.dto';
import { LayerDto } from '../layer/layer.dto';
import { CustomizationUtils } from '../../utils/customization.utils';
import { CreativeUnitDimensionsDto, CreativeUnitDto } from '../creative-unit/creative-unit.dto';
import { CreativeUnitUtils } from '../../utils/creative-unit.utils';
import { AssetDto } from '../../../asset/models/asset.dto';
import { LayersUtils } from '../../utils/layers.utils';
import { cloneDeep, merge } from 'lodash';
import { LayerAudioDto } from '../layer/layer-audio.dto';
import { LayerVideoDto } from '../layer/layer-video.dto';
import { ImageActionType } from '../../../asset/models/image-action.dto';
import { QueryParams } from '../../../data-source/models';
import { DataSource } from 'typeorm';
import { PublicDataSource } from '../../../data-source/data-source.entity';
import { MergeTags } from '../../../_core/utils/utils.merge-tags';
import { Evaluate } from '../../../_core/utils/utils.evaluate';
import { LocaleCollectionDto, LocaleDto } from '../locale.dto';
import { ObjectUtils } from '../../../_core/utils/utils.object';

export enum CustomizationType {
	TEXT = 'text',
	SELECT = 'select',
	TOGGLE = 'toggle',
	IMAGE_PICKER = 'image-picker',
	VIDEO_PICKER = 'video-picker',
	AUDIO_PICKER = 'audio-picker',
	DIMENSIONS = 'dimensions',
	GROUP = 'group'
}

export type CustomizationItemDto =
	| CustomizationTextDto
	| CustomizationSelectDto
	| CustomizationToggleDto
	| CustomizationImagePickerDto
	| CustomizationVideoPickerDto
	| CustomizationAudioPickerDto
	| CustomizationDimensionsDto
	| CustomizationGroupDto

/**
 * An easy way to get each of our customization classes via a type string - only atomic customizations
 */
export function getCustomizationClassByType(type: string) {
	switch (type) {
		case CustomizationType.TEXT:
			return CustomizationTextDto;
		case CustomizationType.SELECT:
			return CustomizationSelectDto;
		case CustomizationType.IMAGE_PICKER:
			return CustomizationImagePickerDto;
		case CustomizationType.VIDEO_PICKER:
			return CustomizationVideoPickerDto;
		case CustomizationType.AUDIO_PICKER:
			return CustomizationAudioPickerDto;
		case CustomizationType.DIMENSIONS:
			return CustomizationDimensionsDto;
		case CustomizationType.TOGGLE:
			return CustomizationToggleDto;
		default:
			return CustomizationDto;
	}
}

export abstract class CustomizationDto {
	@IsOptional()
	@IsString()
	id: string;

	@IsOptional()
	@IsString()
	name?: string;

	@IsOptional()
	@IsString()
	label?: string;

	@IsOptional()
	@IsString()
	hint?: string;

	@IsOptional()
	@IsString()
	placeholder?: string;

	@ValidateNested({ each: true })
	@IsOptional()
	@Type(() => VisibilityConditionDto)
	visibilityConditions?: VisibilityConditionDto | VisibilityConditionDto[];

	@IsOptional()
	setTimeOnFocus?: {
		markerSlug?: string;
		time?: string;
	};

	@IsOptional()
	layerIds?: string[];

	@IsOptional()
	defaultValue?: any;

	@IsOptional()
	@Type(() => CustomizationOptionDto)
	defaultOption?: CustomizationOptionDto;

	// Mappings is used to add extra logic to layers or variables.  Used primarily for media pickers as of now.
	@IsOptional()
	mappings?: CustomizationOptionMappingDto;

	/*
	 * Dimensions: Randomize Options
	 */
	@IsOptional()
	@IsBoolean()
	showRandomize?: boolean;

	@IsOptional()
	@IsNumberString()
	randomizeMaxPixels?: string;

	@IsOptional()
	@IsNumberString()
	randomizeMinPixels?: string;

	@IsOptional()
	locale?: LocaleCollectionDto;

	/**
	 * Retrieves the default value for the customization
	 */
	public static getDefaultValue(customization: CustomizationItemDto, layers?: LayerDto[]): any {
		return customization.value;
	}

	/**
	 * Retrieve the default placeholder value for the customization.
	 *
	 */
	public static getPlaceholderValue(customization: CustomizationItemDto, layers: LayerDto[]): any {
		return customization.placeholder;
	}

	/**
	 * A mapping function to take a value data and retrieve the proper data entity that the value represents.
	 * Ex. passing a string value for a select customization would go find the proper options object
	 * that has that value property, then return the full object as a result.
	 */
	public static findMatchedValue(customization: CustomizationItemDto, value: any): any {
		// Typically we would not want to adopt the value that already exists, only in some types that extend this class.
		return undefined;
	}

	/**
	 * A hook to update a layer based on the value of a customization.
	 */
	public static updateLayer(layer: LayerDto, customization: CustomizationItemDto, value: any, creativeUnit: CreativeUnitDto): LayerDto {
		return layer;
	}

	/**
	 * A hook to update multiple layers based on the value of a customization.
	 */
	public static updateLayers(
		layers: LayerDto[],
		customization: CustomizationItemDto,
		value: any,
		creativeUnit: CreativeUnitDto
	): LayerDto[] {
		return layers;
	}

	/**
	 * Synchronizes values of the ad unit back to any relevant customizations.
	 *
	 * @param {CreativeUnitWithCustomizations} creativeUnit - The ad unit to synchronize values for.
	 * @return {CreativeUnitWithCustomizations} A new `CreativeUnitWithCustomizations` object with synchronized values.
	 */
	public static applyDefaultValues(customizations: CustomizationItemDto[], layers: LayerDto[], mergeTagState: { [key: string]: any }, applyToValue?: boolean): CustomizationItemDto[] {
		// Iterate through the customizations in the layer.
		return CustomizationUtils.mapAllCustomizations(customizations, customization => {
			// Find the customization in the ad unit.
			const customizationClass = getCustomizationClassByType(customization.type);
			let value = customizationClass?.getDefaultValue(customization, layers, mergeTagState) ?? null;

			// Let's apply placeholder values as well.
			let placeholder = customizationClass.getPlaceholderValue(customization, layers);

			// Set the value on the customization.
			let newCustomization = {
				...customization,
				placeholder,
				value
			};

			return newCustomization;
		});
	}

	/**
	 * Build a set of variant customizations based on the different customization values passed in.
	 */
	public static buildVariations(customizations: CustomizationSelectDto[], variantIds: string[]): CustomizationItemDto[][] {
		let combinations = [];

		function recurse(index, currentCombination) {
			if (index === customizations.length) {
				combinations.push([...currentCombination]);
				return;
			}

			const currentObject = customizations[index];

			// If the object's ID is in the provided IDs array, generate combinations for it.
			if (variantIds.includes(currentObject.id)) {
				for (let option of currentObject.options) {
					const newObject = { ...currentObject, value: option };
					recurse(index + 1, [...currentCombination, newObject]);
				}
			}
			// If not, just push it to the current combination and continue the recursion.
			else {
				recurse(index + 1, [...currentCombination, currentObject]);
			}
		}

		recurse(0, []);
		// console.log('Combinations', combinations, customizations, variantIds);
		return combinations;
	}

	public static applyStringValue(customization: CustomizationItemDto, value: string): CustomizationItemDto {
		return customization;
	}

	public static applyMappingsToLayers(layer: LayerDto, customization: CustomizationItemDto, value: any, creativeUnit: CreativeUnitDto): LayerDto {
		let mappings = { ...customization.mappings };

		// console.log('Applying Mappings: Original mappings', customization.label, mappings, customization.value);

		try {
			// console.log('Adding Image Picker variables', customization.mappings?.variables, customization.value);
			let mappingsString = JSON.stringify(customization.mappings);
			mappings = JSON.parse(MergeTags.applyMergeTagsToString(mappingsString, customization.value));

			// Clearn the object over to make sure undefined values are removed.
			mappings = ObjectUtils.cleanObject(mappings);
			// console.log('Cleaned Mappings', mappings);

		} catch(e) { /*console.error(e);*/ }

		// console.log('Applying Mappings: Merged mappings', customization.label, mappings);

		let newLayer = mappings?.layers?.find(l => l.id === layer.id);

		if (newLayer) {
			// Check if there are any creative overrides specifically for this creative unit within the select option.
			// If so, deep override from the package level option overrides.
			let foundUnit = mappings.creativeUnits?.find(cu =>
				cu.creativeUnitId === creativeUnit.creativeUnitId
			);

			if (!foundUnit) {
				foundUnit = mappings.creativeUnits?.find(cu =>
					cu.label === creativeUnit.name
				);
			}

			if (foundUnit) {
				// console.log('Found Unit', foundUnit, layer);
				const unitLayer = foundUnit.layers?.find(l => l.id === layer.id);
				if (unitLayer) {
					// console.log('Fount Unit Layer, Merging', newLayer, unitLayer);
					newLayer = merge(cloneDeep(newLayer || {}), unitLayer);
				}
			}

			if (newLayer) {
				// console.log('Merging Layers', customization.label, layer, newLayer);
				return merge(cloneDeep(layer), newLayer);
			} else {
				return layer;
			}
		} else {
			return layer;
		}
	}
}

export class CustomizationTextDto extends CustomizationDto {
	@IsEnum(CustomizationType)
	type: CustomizationType.TEXT;

	@IsString()
	@IsOptional()
	value?: string;

	@IsString()
	@IsOptional()
	template?: 'text' | 'textarea';

	@IsString()
	@IsOptional()
	variableKey?: string; // If this is set, apply the content to a variable.

	@IsOptional()
	maxLength?: number;

	public static getDefaultValue(customization: CustomizationTextDto, layers?: LayerDto[], mergeTagState?: { [key: string]: any }) {
		if (!layers?.length) {
			return '';
		}

		// Use the value if it exists, otherwise, don't pull a default.
		// This is so that the fields will be blank and the placeholder will fill in.
		return customization.value || undefined;
	}

	public static getPlaceholderValue(customization: CustomizationTextDto, layers?: LayerDto[]) {
		const applicableLayers = CreativeUnitUtils.getLayersFromCustomization(customization, layers) || [];
		return customization.placeholder || applicableLayers?.[0]?.text?.content;
	}

	public static updateLayer(layer: LayerDto, customization: CustomizationTextDto, value: string): LayerDto {
		return {
			...layer,
			text: {
				...layer.text,
				content: value
			}
		};
	}

	public static applyStringValue(customization: CustomizationTextDto, value: string): CustomizationTextDto {
		return {
			...customization,
			value
		};
	}
}

export class ImagePickerActionsTargetCropConfigDto {
	maintainAspectRatio: boolean;
	aspectRatio: number;
	target: {
		x: number;
		y: number;
		width: number;
		height: number;
	}
}

export class ImagePickerActionsDto {
	@IsOptional()
	enabled?: boolean;

	@IsOptional()
	actionsButtonLayout?: 'icon-only' | 'text' | 'icon-and-text';

	@IsOptional()
	actionsButtonLabel?: string;

	@IsOptional()
	actionsButtonIcon?: string;

	@IsOptional()
	@IsArray()
	showActions?: ImageActionType[];

	@IsOptional()
	@IsArray()
	hideActions?: ImageActionType[];

	@IsOptional()
	@IsBoolean()
	minimizeSidebar: boolean;

	@IsOptional()
	targetCropConfig: ImagePickerActionsTargetCropConfigDto;
}

export interface AssetCardLayout {
	id: string;
	name: string;
	width: number;
	height: number;
}

export class AssetPickerConfigDto {
	datasource: PublicDataSource;

	@IsOptional()
	mediaType?: 'image' | 'video' | 'audio' | string;

	@IsOptional()
	@Type(() => QueryParams)
	queryParams?: QueryParams;

	@IsOptional()
	selectedItems?: AssetDto[];

	@IsOptional()
	selectMultiple?: boolean;

	@IsOptional()
	ui?: {
		layout?: AssetCardLayout;
		layoutOptions?: AssetCardLayout[];
		hideToolbar?: boolean;
		hideSearch?: boolean;
		showUpload?: boolean;
	};
}

export class CustomizationMediaPickerDto extends CustomizationDto {
	@IsOptional()
	showSelectButton?: boolean;

	@IsOptional()
	selectButtonLabel?: string;

	@IsOptional()
	showPreview?: boolean;
}

export class CustomizationImagePickerDto extends CustomizationMediaPickerDto {
	@IsEnum(CustomizationType)
	type: CustomizationType.IMAGE_PICKER;

	@IsOptional()
	@ValidateNested()
	@Type(() => AssetDto)
	value?: AssetDto;

	@IsOptional()
	actions?: ImagePickerActionsDto;

	@IsOptional()
	dataSources?: DataSource[];

	@IsOptional()
	@IsBoolean()
	useUnitAssetPath?: boolean; // TODO: Move to Media Picker and implement for video and audio.

	/**
	 * If there isn't a current value, pull in the image asset from the first layer that matches the customization.
	 */
	public static getDefaultValue(customization: CustomizationImagePickerDto, layers?: LayerDto[]) {
		const applicableLayers = CreativeUnitUtils.getLayersFromCustomization(customization, layers) || [];
		// console.log('Applicable Layers', applicableLayers, customization.label, applicableLayers?.[0]?.image);
		return customization.value ||
		{
			...applicableLayers?.[0]?.image,
			mappings: JSON.parse(MergeTags.applyMergeTagsToString(JSON.stringify(customization.mappings || {}), applicableLayers?.[0]?.image))
		};
	}

	public static updateLayer(
		layer: LayerDto,
		customization: CustomizationImagePickerDto,
		value: AssetDto & { mappings?: CustomizationOptionMappingDto },
		creativeUnit: CreativeUnitDto
	): LayerDto {
		// console.log('Update Layer', layer, customization, value);

		return {
			...this.applyMappingsToLayers(layer, customization, value, creativeUnit),
			image: value
		};
	}

	public static updateLayers(
		layers: LayerDto[],
		customization: CustomizationImagePickerDto,
		value: AssetDto & { mappings?: CustomizationOptionMappingDto },
		creativeUnit: CreativeUnitDto
	): LayerDto[] {
		// console.log('Update Layers', creativeUnit.name, layers, customization, value);
		return LayersUtils.mapAllLayers(layers, layer => {
			let newLayer = this.applyMappingsToLayers(layer, customization, value, creativeUnit);

			if (layer.id === customization.layerIds?.[0]) {
				return {
					...newLayer,
					image: value
				};
			} else {
				return newLayer;
			}
		});
	}

	public static applyStringValue(customization: CustomizationImagePickerDto, value: string): CustomizationImagePickerDto {
		return {
			...customization,
			value: {
				...customization.value,
				assetPath: value
			}
		};
	}
}

export class CustomizationVideoPickerDto extends CustomizationMediaPickerDto {
	@IsEnum(CustomizationType)
	type: CustomizationType.VIDEO_PICKER;

	@IsOptional()
	value?: LayerVideoDto | { file: LayerVideoDto; audioConfig: LayerAudioDto };

	@IsOptional()
	dataSources?: DataSource[];

	/**
	 * If there isn't a current value, pull in the video asset from the first layer that matches the customization.
	 */
	public static getDefaultValue(customization: CustomizationItemDto, layers?: LayerDto[]) {
		let val = customization.value;
		if (!customization.value) {
			const applicableLayers = CreativeUnitUtils.getLayersFromCustomization(customization, layers) || [];
			if (applicableLayers?.length) {
				val = {
					file: applicableLayers[0].video,
					audioConfig: applicableLayers[0].audioConfig,
					mappings: JSON.parse(MergeTags.applyMergeTagsToString(JSON.stringify(customization.mappings || {}), applicableLayers?.[0]?.video))
				};
			}
		}

		return val;
	}

	public static updateLayer(
		layer: LayerDto,
		customization: CustomizationItemDto,
		value: { file: AssetDto; audioConfig: LayerAudioDto },
		creativeUnit: CreativeUnitDto
	): LayerDto {
		if (value.file) {
			return {
				...this.applyMappingsToLayers(layer, customization, value, creativeUnit),
				video: merge(cloneDeep(layer.video), value.file),
				audioConfig: merge(cloneDeep(layer.audioConfig), value.audioConfig)
			};
		} else {
			// Backwards compatibility.
			return {
				...this.applyMappingsToLayers(layer, customization, value, creativeUnit),
				video: merge(cloneDeep(layer.video), value)
			};
		}
	}

	public static updateLayers(
		layers: LayerDto[],
		customization: CustomizationItemDto,
		value: { file: AssetDto; audioConfig: LayerAudioDto },
		creativeUnit: CreativeUnitDto
	): LayerDto[] {
		return LayersUtils.mapAllLayers(layers, layer => {
			let newLayer = this.applyMappingsToLayers(layer, customization, value, creativeUnit);

			if (layer.id === customization.layerIds?.[0]) {
				if (value.file) {
					return {
						...newLayer,
						video: merge(cloneDeep(newLayer.video), value.file),
						audioConfig: merge(cloneDeep(newLayer.audioConfig), value.audioConfig)
					};
				} else {
					// Backwards compatibility
					return {
						...newLayer,
						video: merge(cloneDeep(newLayer.video), value)
					};
				}
			} else {
				return newLayer;
			}
		});
	}

	public static applyStringValue(customization: CustomizationVideoPickerDto, value: string): CustomizationVideoPickerDto {
		return {
			...customization,
			value: {
				...customization.value,
				assetPath: value
			}
		};

	}
}

export class CustomizationAudioPickerDto extends CustomizationMediaPickerDto {
	@IsEnum(CustomizationType)
	type: CustomizationType.AUDIO_PICKER;

	@IsOptional()
	value?: { file: AssetDto; audioConfig: LayerAudioDto };

	@IsOptional()
	dataSources?: DataSource[];

	/**
	 * If there isn't a current value, pull in the audio asset from the first layer that matches the customization.
	 */
	public static getDefaultValue(customization: CustomizationItemDto, layers?: LayerDto[]) {
		let val = customization.value;
		if (!customization.value) {
			const applicableLayers = CreativeUnitUtils.getLayersFromCustomization(customization, layers) || [];
			if (applicableLayers?.length) {
				val = {
					file: applicableLayers[0].audio,
					audioConfig: applicableLayers[0].audioConfig,
					mappings: JSON.parse(MergeTags.applyMergeTagsToString(JSON.stringify(customization.mappings || {}), applicableLayers?.[0]?.audio))
				};
			}
		}

		return val;
	}

	public static updateLayer(
		layer: LayerDto,
		customization: CustomizationItemDto,
		value: { file: AssetDto; audioConfig: LayerAudioDto },
		creativeUnit: CreativeUnitDto
	): LayerDto {
		if (value.file) {
			return {
				...this.applyMappingsToLayers(layer, customization, value, creativeUnit),
				audio: merge(cloneDeep(layer.audio), value.file),
				audioConfig: merge(cloneDeep(layer.audioConfig), value.audioConfig)
			};
		} else {
			// Backwards compatibility.
			return {
				...this.applyMappingsToLayers(layer, customization, value, creativeUnit),
				audio: merge(cloneDeep(layer.audio), value)
			};
		}
	}

	public static updateLayers(
		layers: LayerDto[],
		customization: CustomizationItemDto,
		value: { file: AssetDto; audioConfig: LayerAudioDto },
		creativeUnit: CreativeUnitDto
	): LayerDto[] {
		return LayersUtils.mapAllLayers(layers, layer => {
			let newLayer = this.applyMappingsToLayers(layer, customization, value, creativeUnit);

			if (layer.id === customization.layerIds?.[0]) {
				if (value.file) {
					return {
						...newLayer,
						audio: merge(cloneDeep(layer.audio), value.file),
						audioConfig: merge(cloneDeep(layer.audioConfig), value.audioConfig)
					};
				} else {
					// Backwards compatibility.
					return {
						...newLayer,
						audio: merge(cloneDeep(layer.audio), value)
					};
				}
			} else {
				return newLayer;
			}
		});
	}

	public static applyStringValue(customization: CustomizationAudioPickerDto, value: string): CustomizationAudioPickerDto {
		return {
			...customization,
			value: {
				...customization.value,
				file: {
					...customization.value.file,
					assetPath: value
				}
			}
		};
	}
}

export class CustomizationSelectDynamicLinkDto {
	@IsOptional()
	@IsString()
	dataSourceSlug: string;

	@IsOptional()
	@IsString()
	labelKey: string;

	@IsOptional()
	@ValidateNested()
	@Type(() => QueryParams)
	params?: QueryParams;

	@IsOptional()
	@ValidateNested()
	@Type(() => CustomizationOptionMappingDto)
	mapping?: CustomizationOptionMappingDto;
}

export class CustomizationSelectDto extends CustomizationDto {
	@IsEnum(CustomizationType)
	type: CustomizationType.SELECT;

	@ValidateNested()
	@IsOptional()
	@Type(() => CustomizationOptionDto)
	value?: CustomizationOptionDto;

	@ValidateNested({ each: true })
	@IsArray()
	@IsOptional()
	@Type(() => CustomizationOptionDto)
	options: CustomizationOptionDto[];

	@ValidateNested()
	@IsOptional()
	@Type(() => CustomizationOptionDto)
	defaultOption?: CustomizationOptionDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => CustomizationSelectDynamicLinkDto)
	dynamicLink?: CustomizationSelectDynamicLinkDto;

	/**
	 * If the value doesn't exist, pull in the default option from the first layer that matches the customization.
	 * If that doesn't exist, pull in the first option from the list.
	 */
	public static getDefaultValue(customization: CustomizationSelectDto, layers?: LayerDto[], mergeTagState?: { [key: string]: any }) {
		// console.group('Get Default Value', customization.label, customization.name, customization, mergeTagState);
		let values = [customization.value, customization.defaultValue, customization.defaultOption];
		// Validate the value to ensure it exists in the options list.
		for (let value of values) {
			if (!value) {
				continue;
			}
			// console.log('Checking value', value);
			if (value.visibilityConditions?.length) {
				let valid = Evaluate.evaluateMultiple(value.visibilityConditions, mergeTagState);
				if (valid) {
					// console.log('Found a valid default with visibility conditions', value);
					// console.groupEnd();
					return value;
				}
			} else {
				// We're good!
				// console.log('Found a valid default', value);
				// console.groupEnd();
				return value;
			}
		}

		// console.log('Nothing is valid, let\'s find a default option', customization.options);

		// If we get here, let's just iterate through options till we find SOMETHING to use.
		for (let option of customization.options) {
			if (option.visibilityConditions?.length) {
				let valid = Evaluate.evaluateMultiple(option.visibilityConditions, mergeTagState);
				if (valid) {
					// console.log('Found a default option with visbility conditions', option);
					// console.groupEnd();
					return option;
				}
			} else {
				// We're good!
				// console.log('Found a default option', option);
				// console.groupEnd();
				return option;
			}
		}
	}

	public static findMatchedValue(customization: CustomizationSelectDto, selectedOption: CustomizationOptionDto): any {
		// console.log('Find Matched Value', customization, selectedOption);
		const option = customization.options?.find(o => o.id === selectedOption.id);
		return option;
	}

	public static updateLayer(
		layer: LayerDto,
		customization: CustomizationItemDto,
		value: CustomizationOptionDto,
		creativeUnit: CreativeUnitDto
	): LayerDto {
		// console.log('Update Layer', layer, customization, value, creativeUnit);
		let newLayer = value.layer;

		// Check if there are any creative overrides specifically for this creative unit within the select option.
		// If so, deep override from the package level option overrides.
		const foundUnit = value.creativeUnits?.find(cu => cu.id === creativeUnit.id);
		if (foundUnit) {
			// console.log('Found Unit', foundUnit);
			const unitLayer = foundUnit.layers?.find(l => l.id === layer.id);
			if (unitLayer) {
				newLayer = merge(cloneDeep(newLayer), unitLayer);
			}
		}

		return merge(cloneDeep(layer), newLayer);
	}

	public static updateLayers(
		layers: LayerDto[],
		customization: CustomizationItemDto,
		value: CustomizationOptionDto,
		creativeUnit: CreativeUnitDto
	): LayerDto[] {
		// console.log('Update Select Layers', creativeUnit.name, customization.label, layers, customization, value);
		return LayersUtils.mapAllLayers(layers, layer => {
			let newLayer = value.layers?.find(l => l.id === layer.id);
			// console.log('Looking for Layer', layer, newLayer);

			// Check if there are any creative overrides specifically for this creative unit within the select option.
			// If so, deep override from the package level option overrides.
			let foundUnit = value.creativeUnits?.find(cu =>
				cu.creativeUnitId === creativeUnit.creativeUnitId
			);

			if (!foundUnit) {
				foundUnit = value.creativeUnits?.find(cu =>
					cu.label === creativeUnit.name
				);
			}

			// console.log('Looking for Unit', value.creativeUnits, creativeUnit.id, foundUnit);
			if (foundUnit) {
				// console.log('Found Unit', foundUnit, layer);
				const unitLayer = foundUnit.layers?.find(l => l.id === layer.id);
				if (unitLayer) {
					// console.log('Fount Unit Layer, Merging', newLayer, unitLayer);
					newLayer = merge(cloneDeep(newLayer || {}), unitLayer);
				}
			}

			if (newLayer) {
				// console.log('Merging Layers', layer, newLayer);
				return merge(cloneDeep(layer), newLayer);
			} else {
				return layer;
			}
		});
	}

	public static applyStringValue(customization: CustomizationSelectDto, value: string): CustomizationSelectDto {
		const matches = value.match(/\((.*?)\)/);
		const id = matches ? matches[matches.length - 1] : null;

		const option = customization.options.find((item: CustomizationOptionDto) => item.id === id);

		if (!option) {
			return customization;
		}

		return {
			...customization,
			value: option
		};
	}
}

/**
 * Toggle Customization
 * A toggle customization is a select customization that only has two options.
 * It basically works the same as a select, but there is only a true option and a false option.
 */
export class CustomizationToggleDto extends CustomizationDto {
	@IsEnum(CustomizationType)
	type: CustomizationType.TOGGLE;

	@ValidateNested()
	@IsOptional()
	@Type(() => CustomizationOptionDto)
	value?: CustomizationOptionDto;

	@ValidateNested({ each: true })
	@IsArray()
	@IsOptional()
	@Type(() => CustomizationOptionDto)
	options: CustomizationOptionDto[];

	/**
	 * If the value doesn't exist, pull in the default option from the first layer that matches the customization.
	 * If that doesn't exist, pull in the first option from the list.
	 */
	public static getDefaultValue(customization: CustomizationSelectDto, layers?: LayerDto[]) {
		return customization.value || customization.defaultValue || customization.defaultOption || customization.options?.[0];
	}

	public static findMatchedValue(customization: CustomizationSelectDto, selectedOption: CustomizationOptionDto): any {
		// console.log('Find Matched Value', customization, selectedOption);
		const option = customization.options?.find(o => o.value === selectedOption.value);
		return option;
	}

	public static updateLayer(
		layer: LayerDto,
		customization: CustomizationItemDto,
		value: CustomizationOptionDto,
		creativeUnit: CreativeUnitDto
	): LayerDto {
		console.log('Update Layer', layer, customization, value);
		let newLayer = value.layer;

		// Check if there are any creative overrides specifically for this creative unit within the select option.
		// If so, deep override from the package level option overrides.
		const foundUnit = value.creativeUnits?.find(cu => cu.id === creativeUnit.id);
		if (foundUnit) {
			console.log('Found Unit', foundUnit);
			const unitLayer = foundUnit.layers?.find(l => l.id === layer.id);
			if (unitLayer) {
				newLayer = merge(cloneDeep(newLayer), unitLayer);
			}
		}

		return merge(cloneDeep(layer), newLayer);
	}

	public static updateLayers(
		layers: LayerDto[],
		customization: CustomizationItemDto,
		value: CustomizationOptionDto,
		creativeUnit: CreativeUnitDto
	): LayerDto[] {
		// console.log('Update Layers', creativeUnit.name, layers, customization, value);
		return LayersUtils.mapAllLayers(layers, layer => {
			let newLayer = value.layers?.find(l => l.id === layer.id);
			// console.log('Looking for Layer', layer, newLayer);

			// Check if there are any creative overrides specifically for this creative unit within the select option.
			// If so, deep override from the package level option overrides.
			const foundUnit = value.creativeUnits?.find(cu =>
				cu.creativeUnitId === creativeUnit.creativeUnitId ||
				cu.label === creativeUnit.name
			);

			// console.log('Looking for Unit', value.creativeUnits, creativeUnit.id, foundUnit);
			if (foundUnit) {
				// console.log('Found Unit', foundUnit, layer);
				const unitLayer = foundUnit.layers?.find(l => l.id === layer.id);
				if (unitLayer) {
					// console.log('Fount Unit Layer, Mergint', newLayer, unitLayer);
					newLayer = merge(cloneDeep(newLayer || {}), unitLayer);
				}
			}

			if (newLayer) {
				// console.log('Merging Layers', layer, newLayer);
				return merge(cloneDeep(layer), newLayer);
			} else {
				return layer;
			}
		});
	}

	public static applyStringValue(customization: CustomizationSelectDto, value: string): CustomizationSelectDto {
		const matches = value.match(/\((.*?)\)/);
		const id = matches ? matches[matches.length - 1] : null;

		const option = customization.options.find((item: CustomizationOptionDto) => item.id === id);

		if (!option) {
			return customization;
		}

		return {
			...customization,
			value: option
		};
	}
}

export class CustomizationDimensionsDto extends CustomizationDto {
	@IsEnum(CustomizationType)
	type: CustomizationType.DIMENSIONS;

	@IsString()
	@IsOptional()
	value?: CreativeUnitDimensionsDto;

	@IsBoolean()
	@IsOptional()
	resetSizes?: boolean;

	@IsArray()
	@IsOptional()
	@IsString({ each: true })
	canTransformInto?: string[]; // Other units this one can transform into.

	public static getDefaultValue(customization: CustomizationTextDto, layers?: LayerDto[]) {
		// Use the value if it exists, otherwise, don't pull a default.
		// This is so that the fields will be blank and the placeholder will fill in.
		return customization.value || undefined;
	}

	public static updateLayer(layer: LayerDto, customization: CustomizationTextDto, value: string): LayerDto {
		return;
	}
}

export class CustomizationGroupDto extends CustomizationDto {
	@IsEnum(CustomizationType)
	type: CustomizationType.GROUP;

	@IsOptional()
	@IsString()
	value?: string = null;

	@ValidateNested({ each: true })
	@IsArray()
	@IsOptional()
	@Type(() => CustomizationDto, {
		keepDiscriminatorProperty: true,
		discriminator: {
			property: 'type',
			subTypes: [
				// TODO: Find a way to use the type array below here.
				{ value: CustomizationTextDto, name: CustomizationType.TEXT },
				{ value: CustomizationSelectDto, name: CustomizationType.SELECT },
				{ value: CustomizationToggleDto, name: CustomizationType.TOGGLE },
				{ value: CustomizationImagePickerDto, name: CustomizationType.IMAGE_PICKER },
				{ value: CustomizationVideoPickerDto, name: CustomizationType.VIDEO_PICKER },
				{ value: CustomizationAudioPickerDto, name: CustomizationType.AUDIO_PICKER },
				{ value: CustomizationDimensionsDto, name: CustomizationType.DIMENSIONS },
				{ value: CustomizationGroupDto, name: CustomizationType.GROUP }
			]
		}
	})
	children?: CustomizationItemDto[];
}

export const CustomizationTypeArray = [
	{ value: CustomizationTextDto, name: CustomizationType.TEXT },
	{ value: CustomizationSelectDto, name: CustomizationType.SELECT },
	{ value: CustomizationToggleDto, name: CustomizationType.TOGGLE },
	{ value: CustomizationImagePickerDto, name: CustomizationType.IMAGE_PICKER },
	{ value: CustomizationVideoPickerDto, name: CustomizationType.VIDEO_PICKER },
	{ value: CustomizationAudioPickerDto, name: CustomizationType.AUDIO_PICKER },
	{ value: CustomizationDimensionsDto, name: CustomizationType.DIMENSIONS },
	{ value: CustomizationGroupDto, name: CustomizationType.GROUP }
];
