import { uuid } from 'aws-sdk/clients/customerprofiles';
import { Column, Entity, JoinColumn, JoinTable, ManyToMany, ManyToOne, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { Asset, PublicAsset } from '../asset/asset.entity';
import { PaymentMethod, PublicPaymentMethod } from '../paymentmethod/paymentmethod.entity';
import { PublicSite, Site } from '../site/site.entity';
import { PublicUser, User } from '../user/user.entity';
import { BusinessRole, BusinessUser } from './business-user.entity';
import { Product } from '../product/product.entity';
import { Country } from '../_core/utils/utils.data';
import { PublicTag, Tag } from '../tags/tag.entity';
import { BusinessesTag, PublicBusinessesTag } from '../tags/businessesTag.entity';

export enum BusinessAccessLevel {
	Read = 'read',
	Write = 'write',
	Admin = 'admin'
}

export type PublicBusiness = Pick<
	Business,
	| 'id'
	| 'name'
	| 'address'
	| 'phone'
	| 'website'
	| 'billingAddress'
	| 'billingCity'
	| 'billingState'
	| 'isApprover'
	| 'billingZip'
	| 'hasActiveSubscription'
	| 'delinquent'
	| 'freeTrialExpires'
	| 'siteId'
	| 'modified'
	| 'created'
	| 'paymentMethodId'
	| 'internalBusiness'
	| 'defaultSubscriptionProduct'
	| 'defaultItemProduct'
	| 'stripeCustomerId'
	| 'stripeSubscriptionId'
	| 'signUpMetadata'
	| 'country'
	| 'businessTags'
	| 'externalPaymentId'
> & {
	users?: PublicUser[];
	businessUsers?: BusinessUser[];
	role?: BusinessRole;
	site?: PublicSite;
	logo?: PublicAsset;
	paymentMethod?: PublicPaymentMethod;
	businessTags?: PublicBusinessesTag[];
};

@Entity('businesses')
export class Business {
	constructor(value?: Partial<Business>) {
		for (const k in value) {
			this[k] = value[k];
		}
	}

	@PrimaryGeneratedColumn('uuid')
	public id: string;

	@Column('text', { nullable: false })
	public name: string;

	@Column('text', { nullable: true })
	public address?: string;

	@Column('text', { nullable: true })
	public phone?: string;

	@Column('text', { nullable: true })
	public website?: string;

	@Column('text', { nullable: true })
	public country: Country;

	@Column('text', { nullable: true })
	public billingAddress?: string;

	@Column('text', { nullable: true })
	public billingCity?: string;

	@Column('text', { nullable: true })
	public billingState?: string;

	@Column('text', { nullable: true })
	public billingZip?: string;

	public isApprover?: boolean;

	@Column('text', { nullable: true })
	public stripeCustomerId?: string;

	@Column('text', { nullable: true })
	public stripeSubscriptionId?: string;

	@Column('boolean', { nullable: false, default: false })
	public hasActiveSubscription?: boolean;

	@Column('boolean', { nullable: false, default: false })
	public hasCustomerId?: boolean;

	@Column('boolean', { nullable: false, default: false })
	public delinquent?: boolean;

	@Column('boolean', { nullable: false, default: false })
	public internalBusiness?: boolean;

	// @Column('text', { nullable: false })
	// public remoteId: string;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public created: string;

	@Column({ type: 'timestamptz', default: () => 'NOW()' })
	public modified: string;

	@Column({ type: 'jsonb', nullable: true })
	public signUpMetadata: any;

	@Column('uuid', { nullable: true })
	public siteId?: string;
	@ManyToOne(
		() => Site,
		site => site.id,
		{
			nullable: true
		}
	)
	@JoinColumn({ name: 'siteId' })
	public site: Site;

	@OneToMany(
		() => BusinessUser,
		businessUser => businessUser.business,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinTable({ name: 'businessUsers' })
	public businessUsers?: BusinessUser[] | Partial<BusinessUser[]>;

	@ManyToOne(
		() => Asset,
		asset => asset.id,
		{
			eager: false,
			nullable: true,
			onDelete: 'SET NULL'
		}
	)
	@JoinColumn({ name: 'logoAssetId' })
	public logo: Asset | Partial<Asset>;

	@Column('text', { nullable: true })
	public externalPaymentId?: string;

	@Column('uuid', { nullable: true })
	public paymentMethodId?: uuid;
	@ManyToOne(
		() => PaymentMethod,
		paymentMethod => paymentMethod.id,
		{
			eager: false,
			nullable: true,
			onDelete: 'SET NULL'
		}
	)
	@JoinColumn({ name: 'paymentMethodId' })
	public paymentMethod?: PaymentMethod | Partial<PaymentMethod>;

	@Column('timestamptz', { nullable: true })
	public freeTrialExpires?: string;

	@ManyToOne(() => Product)
	@JoinColumn({ name: 'default_subscription_product_id' })
	defaultSubscriptionProduct: Product;

	@Column({ type: 'text', name: 'default_subscription_product_id', nullable: true })
	defaultSubscriptionProductId: string;

	@ManyToOne(() => Product)
	@JoinColumn({ name: 'default_item_product_id' })
	defaultItemProduct: Product;

	@Column({ type: 'text', name: 'default_item_product_id', nullable: true })
	defaultItemProductId: string;

	public users?: User[];
	public role?: BusinessRole;

	@OneToMany(
		() => BusinessesTag,
		businessTag => businessTag.business,
		{
			onDelete: 'CASCADE'
		}
	)
	@JoinTable({ name: 'businessTags' })
	public businessTags?: PublicBusinessesTag[];

	public toPublic(self: boolean = false) {
		let pub: Partial<PublicBusiness> = {
			id: this.id,
			name: this.name,
			address: this.address,
			phone: this.phone,
			website: this.website,
			billingAddress: this.billingAddress,
			billingCity: this.billingCity,
			billingState: this.billingState,
			billingZip: this.billingZip,
			modified: this.modified,
			hasActiveSubscription: this.hasActiveSubscription,
			created: this.created,
			siteId: this.siteId,
			delinquent: this.delinquent,
			freeTrialExpires: this.freeTrialExpires,
			paymentMethodId: this.paymentMethodId,
			internalBusiness: this.internalBusiness,
			defaultSubscriptionProduct: this.defaultSubscriptionProduct,
			defaultItemProduct: this.defaultItemProduct,
			stripeCustomerId: this.stripeCustomerId,
			stripeSubscriptionId: this.stripeSubscriptionId,
			signUpMetadata: this.signUpMetadata,
			country: this.country,
			externalPaymentId: this.externalPaymentId,
			businessTags: this.businessTags
		};

		if (this.businessUsers?.length) {
			pub.users = this.businessUsers.map(u => ({
				businessUserId: u.id,
				businessRole: u.role,
				internal: u.internal,
				...new BusinessUser(u).toUser().toPublic(true)
			}));

			pub.businessUsers = this.businessUsers.map(u => new BusinessUser(u).toPublic());
		}

		if (this.paymentMethod) {
			pub.paymentMethod = new PaymentMethod(this.paymentMethod).toPublic();
		}

		// if (this.tags?.length) {
		// 	pub.tags = this.tags.map(t => new Tag(t).toPublic());
		// }

		if (!this.defaultSubscriptionProduct) {
			// find default subscription product from site
			if (this.site && this.site.defaultSubscriptionProductId) {
				pub.defaultSubscriptionProduct = this.site.defaultSubscriptionProduct;
			}
		}

		if (!this.defaultItemProduct) {
			// find default item product from site
			if (this.site && this.site.defaultItemProductId) {
				pub.defaultItemProduct = this.site.defaultItemProduct;
			}
		}

		// if (this.users?.length) {
		// 	pub.users = this.users.map(u => new User(u).toPublic());
		// }

		if (this.businessTags?.length) {
			pub.businessTags = this.businessTags.map(t => new BusinessesTag(t).toPublic());
		}

		if (this.role) {
			pub.role = this.role;
		}

		// if (this.site) {
		// 	pub.site = new Site(this.site).toPublic();
		// }

		if (this.logo) {
			pub.logo = new Asset(this.logo).toPublic();
		} else if (this.logo === null) {
			pub.logo = null;
		}

		// if (this.site) {
		// 	pub.site = new Site(this.site).toPublic();
		// }

		return pub as PublicBusiness;
	}
}
