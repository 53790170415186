import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DisclaimerDialogComponentData {
	title?: string;
	message: string;
	showInstructions?: boolean
	instructionsText?: string
	hideAcceptButton?: boolean;
	acceptButtonText?: boolean;
	acceptButtonColor?: string;
	showDeclineButton?: boolean;
	declineButtonText?: string;
	requireRead?: boolean;
	showClose?: boolean;
}

/**
 * Disclaimer Dialog
 * Use to confirm a disclaimer.
 * Supports skip, custom messages, custom buttons as required to read
 */
@Component({
	selector: 'app-disclaimer-dialog',
	templateUrl: './disclaimer-dialog.component.html',
	styleUrls: ['./disclaimer-dialog.component.scss']
})
export class DisclaimerDialogComponent {

	readDisclaimer = false;

	constructor(
		public dialogRef: MatDialogRef<DisclaimerDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DisclaimerDialogComponentData
	) {
	}

	accept() {
		this.dialogRef.close(true);
	}

	skip() {
		this.dialogRef.close();
	}

	public scrollDisclaimer(event: any) {
		// visible height + pixel scrolled >= total height
		if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
			this.readDisclaimer = true;
		}
	}

}
