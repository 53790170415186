import { Injectable } from '@angular/core';
import { SessionStore } from './session.store';
import { catchError, tap } from 'rxjs/operators';
import { LoginResponse, VerifyResponse, Profile } from './session.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BusinessStore } from '../business';
import { UserSignUpRequestDto } from '../../../../../../api/src/user/models/user-sign-up-request.dto';
import { PublicBusiness } from '../../../../../../api/src/business/business.entity';
import { arrayRemove, arrayUpsert } from '@datorama/akita';
import { BusinessUser } from '../../../../../../api/src/business/business-user.entity';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	public basePath = environment.apiUrl;
	public defaultHeaders = new HttpHeaders({
		Accept: 'application/json'
	});

	constructor(private sessionStore: SessionStore, protected httpClient: HttpClient, private readonly businessStore: BusinessStore) {}

	public requestCode(email: string) {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return (
			this.httpClient
				.post<VerifyResponse>(
					`${environment.apiUrl}/user/auth/request-sign-in`,
					{ email, siteId: environment.organizationId },
					{ headers }
				)
				// .post<LoginResponse>(
				// 	`${this.basePath}/user/auth/basic/generate-sign-in-code`,
				// 	{ email, siteId: environment.organizationId },
				// 	{ headers }
				// )
				.pipe(
					tap(() => this.sessionStore.setLoading(false)),
					catchError(err => {
						this.sessionStore.setLoading(false);
						throw err;
					})
				)
		);
	}

	public signUp(request: Partial<UserSignUpRequestDto>) {
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);
		request.siteId = environment.organizationId;

		return this.httpClient.post<LoginResponse>(`${this.basePath}/user/signup`, request, { headers }).pipe(
			tap(resp => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.sessionStore.setLoading(false);
				throw err;
			})
		);
	}

	public activateEmail(email: string, singlePass: string) {
		// http call to verify code
		const params = {
			singlePass,
			email,
			siteId: environment.organizationId
		};
		const headers = this.defaultHeaders;
		this.sessionStore.setLoading(true);

		return this.httpClient.post<LoginResponse>(`${this.basePath}/user/auth/basic/code-sign-in`, params, { headers }).pipe(
			tap(resp => {
				this.sessionStore.login({
					redirect: resp.redirect,
					token: resp.token,
					profile: resp.profile,
					businessUser: resp.businessUser
				});

				this.businessStore.set(resp?.profile?.businesses || []);

				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.sessionStore.setLoading(false);
				throw err;
			})
		);
	}

	public getUserStatus(token: string) {
		this.sessionStore.setLoading(true);
		return this.httpClient.get<LoginResponse>(`${this.basePath}/user/me`).pipe(
			tap((resp: LoginResponse) => {
				this.sessionStore.login({
					token: resp.token,
					profile: resp.profile,
					businessUser: resp.businessUser
				});

				this.businessStore.set(resp?.profile?.businesses || []);

				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.sessionStore.setLoading(false);
				throw err;
			})
		);
	}

	public updateProfile(profile: Partial<Profile['settings']>) {
		//this.sessionStore.updateProfile(profile);
		const headers = this.defaultHeaders;
		return this.httpClient.put(`${this.basePath}/user/settings`, profile, { headers }).pipe(
			tap((resp: any) => {
				this.sessionStore.updateProfile({
					settings: resp.settings
				});
			})
		);
	}

	public upsertBusinessToProfile(business: PublicBusiness) {
		this.sessionStore.update(state => ({
			...state,
			profile: {
				...state.profile,
				businesses: arrayUpsert(state.profile.businesses || [], business.id, business)
			}
		}));
	}

	public upsertBusinessUsers(businessUsers: BusinessUser[]) {
		if (!businessUsers?.length) {
			return;
		}

		this.sessionStore.update(state => {
			let currentUsers = state.businessUser || [];

			businessUsers.forEach(businessUser => (currentUsers = arrayUpsert(currentUsers, businessUser.id, businessUser)));

			return {
				...state,
				businessUser: currentUsers
			};
		});
	}

	public removeBusinessFromProfile(businessId: PublicBusiness['id']) {
		this.sessionStore.update(state => ({
			...state,
			profile: {
				...state.profile,
				businesses: arrayRemove(state.profile.businesses || [], businessId)
			}
		}));
	}

	public inviteUsers(inviteArray: any) {
		const headers = this.defaultHeaders;

		return this.httpClient.post(`${this.basePath}/user/invite/${inviteArray.businessId}`, inviteArray, { headers }).pipe(
			tap((resp: any) => {
				// this.sessionStore.updateProfile({
				// 	settings: resp.settings
				// });
			})
		);
	}

	public acceptTerms() {
		return this.httpClient.put(`${this.basePath}/user/accept-terms`, {}).pipe(
			tap((resp: any) => {
				this.sessionStore.update(state => ({
					...state,
					profile: {
						...state.profile,
						...resp
					}
				}));
			})
		);
	}

	public logout() {
		this.sessionStore.logout();
		this.businessStore.set([]);
	}
}
