import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import tippy from 'tippy.js';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TippyProps } from '@ngneat/helipopper/lib/tippy.types';
import { ResizeObservable } from '../../_core/utils/dom.utils';

/**
 * Ellipsis Tooltip Directive
 * A Directive to add tippy tooltip to an element when it has overflow
 */
@Directive({
	selector: '[ellipsisTooltip]'
})

export class EllipsisTooltipDirective implements OnDestroy {

	readonly defaultTooltipSettings: Partial<TippyProps> = {
		theme: 'dark',
		placement: 'bottom',
		arrow: true,
		allowHTML: true,
		delay: [300, 0],
	}

	@Input() ellipsisTooltip: boolean = true;
	@Input() ellipsisTooltipSettings = this.defaultTooltipSettings;

	/**
	 * when bellow input is used the text is shown even if content doesn't overflow
	**/
	@Input() ellipsisTooltipText: string | null = null;

	/**
	 * the directive by default checks if element children are overflowing
	 * if you want to check the element itself set this input to true
	**/
	@Input() checkAppliedElement: boolean = false;

	private tooltipInstance: any;
	private resizeSubscription: Subscription;


	constructor(elementRef: ElementRef) {
		if (!this.ellipsisTooltip) {
			return;
		}
		this.resizeSubscription = new ResizeObservable(elementRef.nativeElement)
			.pipe(debounceTime(200))
			.subscribe(resizeEntry => this.onResize(resizeEntry.target));
	}

	public ngOnDestroy(): void {
		this.resizeSubscription?.unsubscribe();
	}

	onResize(element: any): void {
		let hasOverflow: unknown;

		if (this.checkAppliedElement) {
			// check the element that has directive applied
			hasOverflow = element.offsetWidth < element.scrollWidth;
		} else {
			// check element children for overflow
			hasOverflow = Array.from(element.children).find((child: any) => child.offsetWidth < child.scrollWidth);
		}

		if (hasOverflow || this.ellipsisTooltipText) {
			if (this.tooltipInstance) {
				this.tooltipInstance.enable();
			} else {
				this.tooltipInstance = tippy(element, {
					content: this.ellipsisTooltipText || element.innerHTML,
					...this.ellipsisTooltipSettings
				});
			}
		} else {
			if (this.tooltipInstance) {
				this.tooltipInstance.disable();
			}
		}
	}
}


