import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { RequestInterceptor } from './interceptors/request.interceptor';
import { MusicQuery } from './state/music/music.query';
import { materialModules } from '../angular-material.module';
import { TextSingularPluralPipe } from "../shared/pipes/text-singular-plural.pipe";

@NgModule({
	imports: [CommonModule, ...materialModules],
	exports: [],
	declarations: [
  ],
	providers: [
		MusicQuery,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true
		},
		TextSingularPluralPipe
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error(`Core Module has already been loaded. Import Core modules in the AppModule only.`);
		}
	}
}
