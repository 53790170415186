import { IsArray, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { AssetDto } from "./asset.dto";
import { Type } from "class-transformer";

export enum ImageActionType {
	BackgroundRemoval = 'background-removal',
	BlurBackground = 'blur-background',
	ReplaceBackground = 'replace-background',
	TextToImage = 'text-to-image',
	TargetCrop = 'target-crop'
}

export type ImageActionConfigItemDto =
	| ImageActionBackgroundRemovalDto
	| ImageActionBlurBackgroundDto
	| ImageActionReplaceBackgroundDto
	| ImageActionTextToImageDto;


export class ImageActionConfigDto {
	@IsOptional()
	asset?: Partial<AssetDto>; // This is a partial asset because we only need the assetPath and we will not always have the full asset object.
}

export class ImageActionBackgroundRemovalDto extends ImageActionConfigDto {}
export class ImageActionBlurBackgroundDto extends ImageActionConfigDto {}

/**
 * Text to Image
 * This is the DTO for the replace background action.
 */
export enum TextToImageStyle {
	PhotoRealistic = 'photo realistic',
	Fashion = 'fashion',
	Retro = 'retro',
	Sports = 'sports',
	StillLife = 'still life',
	HyperRealistic = 'hyper realistic',
	Travel = 'travel',
	Artistic = 'artistic',
	"3dRender" = "3d render",
	Realistic = "realistic",
	Fantasy = "fantasy",
	ComicBook = "comic book",
	Minimalistic = "minimalistic"
}

export enum TextToImageAtmosphere {
	Dramatic = 'dramatic',
	Vivid = 'vivid',
	Warm = 'warm',
	Cool = 'cool'
}

export enum TextToImageCamera {
	Portrait = 'portrait',
	CloseUp = 'close up',
	Aerial = 'aerial',
	Landscape = 'landscape'
}

export enum TextToImageMedium {
	Photography = 'photography',
	Art = 'art'
}

export class ImageActionTextToImageDto extends ImageActionConfigDto {
	@IsString()
	prompt: string;

	@IsOptional()
	@IsNumber()
	num_results?: number;

	@IsOptional()
	@IsArray()
	size?: [number, number];

	@IsOptional()
	@IsNumber()
	seed?: number;

	@IsOptional()
	@IsString()
	negative_prompt?: string;

	@IsOptional()
	@IsNumber()
	steps_num?: number;

	@IsOptional()
	@IsNumber()
	text_guidance_scale?: number;

	@IsOptional()
	@IsString()
	style?: TextToImageStyle;

	@IsOptional()
	@IsString()
	atmosphere?: TextToImageAtmosphere;

	@IsOptional()
	@IsString()
	camera?: TextToImageCamera;

	@IsOptional()
	@IsString()
	medium?: TextToImageMedium;
}

/**
 * Replace Background
 * This is the DTO for the replace background action.
 */
export enum PlacementType {
	Original = 'original',
	Automatic = 'automatic',
	ManualPlacement = 'manual_placement',
	ManualPadding = 'manual_padding'
}

export class ImageActionReplaceBackgroundDto extends ImageActionConfigDto {
	@IsString()
	bg_prompt: string;

	@IsOptional()
	@IsString()
	negative_prompt?: string;

	@IsOptional()
	placement_type?: PlacementType;

	@IsOptional()
	@IsNumber()
	num_results?: number;

	@IsOptional()
	@IsArray()
	canvas_size?: [number, number];

	@IsOptional()
	@IsArray()
	manual_placement_selection?: [string, string];

	@IsOptional()
	@IsArray()
	padding_values?: [number, number, number, number];

	@IsOptional()
	@IsNumber()
	seed?: number;
}


/**
 * Main Image Action DTO
 */
export class ImageActionDto<T> {
	@IsString()
	action: ImageActionType;

	// @ValidateNested()
	@IsOptional()
	// @Type(() => ImageActionConfigDto, {
	// 	keepDiscriminatorProperty: true,
	// 	discriminator: {
	// 		property: 'action',
	// 		subTypes: [
	// 			{ value: ImageActionBackgroundRemovalDto, name: ImageActionType.BackgroundRemoval },
	// 			{ value: ImageActionBlurBackgroundDto, name: ImageActionType.BlurBackground },
	// 			{ value: ImageActionReplaceBackgroundDto, name: ImageActionType.ReplaceBackground }
	// 		]
	// 	}
	// })
	config?: T;
}
