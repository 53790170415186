import { Injectable } from '@angular/core';
import { filterNil, Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { GlobalStore, GlobalState } from './global.store';
import { StoryQuery, VideoFormat } from '../story';
import { StorySegmentQuery, StorySegment } from '../story-segment';
import { combineLatest } from 'rxjs';
import { MusicQuery, Music } from '../music';
import { Clip, ClipQuery } from '../clip';
import { SurveyQuery } from '../survey/survey.query';
import { EncodeJobObject } from './global.model';
import { VoiceoverQuery } from '../voiceover/voiceover.query';
import { Project, ProjectsQuery } from '../project';
import { SessionQuery } from '../session';
import { StoryFormatQuery } from '../story-format';
import { CartQuery } from "../cart/cart.query";

@Injectable({ providedIn: 'root' })
export class GlobalQuery extends Query<GlobalState> {
	public organizationLogo$ = this.select().pipe(map(state => state.settings?.organizationLogo[0]?.url));

	public authenticatedSettings$ = this.select('settings').pipe(filterNil);

	public pages$ = combineLatest([
		this.select('pages'),
		this.surveyQuery.areSurveysValid$,
		this.musicQuery.isMusicValid$,
		this.voiceoverQuery.allSelectedVoiceoversReady$,
		this.storySegmentQuery.areSegmentsValid$,
		this.projectQuery.validName$,
		this.storyQuery.clipsLoaded$,
		this.cartQuery.selectActive()
	]).pipe(
		map(([pages, surveysValid, musicValid, voiceoverReady, segmentsFilled, validProjectName, clipsLoaded, cartItem]) => {
			return pages.map(page => {
				let valid = false;
				let disabledReason = page.disabledReason;

				// Here's our custom validation to make sure we can get to each page
				switch (page.id) {
					case 'get-started':
					case 'ad-studio':
						valid = true;
						break;

					case 'editor':
						if (cartItem) {
							if (cartItem.name === '') {
								break;
							}
						}
						if (surveysValid && validProjectName && clipsLoaded) {
							valid = true;
						}
						break;

					case 'music':
						if (surveysValid && segmentsFilled) {
							valid = true;
						}
						break;

					case 'review':
						if (surveysValid && segmentsFilled && musicValid && voiceoverReady) {
							valid = true;
						}
						break;

					case 'checkout':
						if (!this.projectQuery.getActive()?.business?.hasActiveSubscription) {
							disabledReason = 'You must have an active subscription to checkout.';
							break;
						}

						if (this.sessionQuery.canPurchaseForBusiness(this.projectQuery.getActive()?.business?.id)) {
							if (this.projectQuery.isEncodeJobLatest(this.projectQuery.getActiveEncodeJob())) {
								valid = true;
							} else {
								disabledReason = 'You cannot purchase this project because it is not the latest version.';
							}
						} else {
							disabledReason = 'You must have permission from the business owner to purchase.';
						}
						break;
				}

				return { ...page, valid, disabledReason };
			});
		})
	);

	public nextState$ = combineLatest([this.pages$, this.select('state')]).pipe(
		map(([pages, state]) => {
			let match;

			pages.forEach((page, i) => {
				if (page.id === state) {
					match = pages[i + 1];
				}
			});

			return match;
		})
	);

	/**
	 * Specific query for all the copy settings coming from the CMS
	 */
	public copy$ = this.select().pipe(
		map(state => ({
			getStartedStoryFormatTitle: state.settings?.getStartedStoryFormatTitle,
			getStartedStoryFormatInstruction: state.settings?.getStartedStoryFormatInstruction,
			getStartedVideoFormatTitle: state.settings?.getStartedVideoFormatTitle,
			getStartedVideoFormatInstruction: state.settings?.getStartedVideoFormatInstruction,
			getStartedStoryTitle: state.settings?.getStartedStoryTitle,
			getStartedStoryInstruction: state.settings?.getStartedStoryInstruction,
			finalizeVideoPreviewTitle: state.settings?.finalizeVideoPreviewTitle,
			finalizeVideoPreviewInstruction: state.settings?.finalizeVideoPreviewInstruction,
			transcriptDownloadCta: state.settings?.transcriptDownloadCta,
			transcriptDownloadInstructions: state.settings?.transcriptDownloadInstructions,
			transcriptDownloadHeader: state.settings?.transcriptDownloadHeader,
			reviewPreviewHeader: state.settings?.reviewPreviewHeader,
			reviewPreviewInstructions: state.settings?.reviewPreviewInstructions,
			previewShareLinksInstructions: state.settings?.previewShareLinksInstructions,
			offlinePaymentImportTitle: state.settings?.offlinePaymentImportTitle,
			offlinePaymentImportInstruction: state.settings?.offlinePaymentImportInstruction,
			offlinePaymentImportDialogTitle: state.settings?.offlinePaymentImportDialogTitle,
			offlinePaymentImportDialogInstruction: state.settings?.offlinePaymentImportDialogInstruction,
			offlinePaymentFileName: state.settings?.offlinePaymentFileName,
			offlinePaymentOnboardDialogTitle: state.settings?.offlinePaymentOnboardDialogTitle,
			offlinePaymentOnboardDialogCopy: state.settings?.offlinePaymentOnboardDialogCopy,
			freeTrialEnabled: state.settings?.freeTrialEnabled,
			billingVerificationThreshold: state.settings?.billingVerificationThreshold,
			enableIntercom: state.settings?.enableIntercom,
			disableVersion1Projects: state.settings?.disableVersion1Projects,
			enableHotjar: state.settings?.enableHotjar,
			businessNameLabelPlural: state.settings?.businessNameLabelPlural,
			businessNameLabelSingular: state.settings?.businessNameLabelSingular,
			heroGetStartedBackground: state.settings?.heroGetStartedBackground,
			csvDownloadExportEnabled: state.settings?.csvDownloadExportEnabled,
			paymentMethodsRequired: state.settings?.paymentMethodsRequired,
			terms: state.settings?.terms,
			siteTitle: state.settings?.siteTitle,
			disclaimer: state.settings?.disclaimer,
			signupCopy: state.settings?.signupCopy,
			defaultLocale: state.settings?.defaultLocale,
			signupPrivacyPolicy: state.settings?.signupPrivacyPolicy,
			footerCopyrightText: state.settings?.footerCopyrightText,
			showFooter: state.settings?.showFooter,
			footerLinks: state.settings?.footerLinks,
			signupCrossBorderTransfer: state.settings?.signupCrossBorderTransfer,
			metadata: state.settings?.metadata,
			exportsRequireApproval: state.settings?.exportsRequireApproval,
			projectPageCheckoutButtonLabel: state.settings?.projectPageCheckoutButtonLabel,
			projectPageCheckoutDisabledTooltip: state.settings?.projectPageCheckoutDisabledTooltip,
			projectPageCheckoutTitle: state.settings?.projectPageCheckoutTitle,
			checkoutSubtitle: state.settings?.checkoutSubtitle,
			checkoutTermsSubtitle: state.settings?.checkoutTermsSubtitle,
			checkoutButtonLabel: state.settings?.checkoutButtonLabel,
			downloadPageCongratulationsTitle: state.settings?.downloadPageCongratulationsTitle,
		}))
	);

	public encodeJobObject$: Observable<EncodeJobObject> = combineLatest([
		this.storyFormatQuery.selectActive(),
		this.storySegmentGroupQuery.selectActive(),
		this.storySegmentQuery.selectEntitiesWithUI(),
		this.musicQuery.select('selectedMusic'),
		this.voiceoverQuery.selectedVoiceovers$,
		this.clipQuery.selectAll()
	]).pipe(
		map(([storyFormat, _story, segments, music, voiceovers, _clips]) => ({
			storyFormat,
			segments,
			clips: segments.map(s => this.storySegmentQuery.getActiveClip(s.id)),
			music,
			voiceovers
		}))
	);

	constructor(
		protected override readonly store: GlobalStore,
		private readonly storyFormatQuery: StoryFormatQuery,
		private readonly storySegmentGroupQuery: StoryQuery,
		private readonly storySegmentQuery: StorySegmentQuery,
		private readonly musicQuery: MusicQuery,
		private readonly surveyQuery: SurveyQuery,
		private readonly clipQuery: ClipQuery,
		private readonly storyQuery: StoryQuery,
		private readonly voiceoverQuery: VoiceoverQuery,
		private readonly projectQuery: ProjectsQuery,
		private readonly sessionQuery: SessionQuery,
		private readonly cartQuery: CartQuery,
	) {
		super(store);
	}

	getActivePage() {
		let state = this.getValue().state;
		return this.getValue().pages.find(page => page.id === state);
	}

	isProjectDirty(id: Project['id']) {
		const modified: any = this.projectQuery.getEntity(id)?.modified;
		const dirtyTick = this.getValue().dirtyTick;

		return new Date(modified) < new Date(dirtyTick || 0);
	}
}
