export enum LocaleCode {
	enUS = 'en-US',
	ptBR = 'pt-BR',
	ptPT = 'pt-PT',
	deDE = 'de-DE',
	frFR = 'fr-FR',
	frCA = 'fr-CA',
	esSP = 'es-SP',
	itIT = 'it-IT',
	esAR = 'es-AR',
	enGB = 'en-GB',
	nlNL = 'nl-NL',
	hrHR = 'hr-HR',
	// svSE = 'sv-SE',
	// nbNO = 'nb-NO',
	// fiFI = 'fi-FI',
	// daDK = 'da-DK',
	plPL = 'pl-PL',
	csCZ = 'cs-CZ',
	huHU = 'hu-HU',
	skSK = 'sk-SK',
	roRO = 'ro-RO',
	elGR = 'el-GR',
	bgBG = 'bg-BG',
	slSI = 'sl-SI',
	srLatnRS = 'sr-Latn-RS',
	heIL = 'he-IL',
	thTH = 'th-TH',
	zhHK = 'zh-HK',
	zhCN = 'zh-CN',
	zhHant = 'zh-Hant'
}

export class Locale {
	name: string;
	value: LocaleCode;
}

export const CodeArray = [
	'en-US',
	'pt-BR',
	'pt-PT',
	'de-DE',
	'fr-FR',
	'fr-CA',
	'es-SP',
	'it-IT',
	'es-AR',
	'en-GB',
	'nl-NL',
	'hr-HR',
	// 'sv-SE',
	// 'nb-NO',
	// 'fi-FI',
	// 'da-DK',
	'pl-PL',
	'cs-CZ',
	'hu-HU',
	'sk-SK',
	'ro-RO',
	'el-GR',
	'bg-BG',
	'sl-SI',
	'sr-Latn-RS',
	'he-IL',
	'th-TH',
	'zh-HK',
	'zh-CN',
	'zh-Hant'
];

export const validStripeLocales = {
	"bg": "Bulgarian",
	"hr": "Croatian",
	"cs": "Czech",
	"da": "Danish",
	"nl": "Dutch",
	"en": "English",
	"et": "Estonian",
	"fi": "Finnish",
	"fil": "Filipino",
	"fr": "French",
	"de": "German",
	"el": "Greek",
	"hu": "Hungarian",
	"id": "Indonesian",
	"it": "Italian",
	"ja": "Japanese",
	"ko": "Korean",
	"lv": "Latvian",
	"lt": "Lithuanian",
	"ms": "Malay",
	"mt": "Maltese",
	"nb": "Norwegian Bokmål",
	"pl": "Polish",
	"pt": "Portuguese",
	"ro": "Romanian",
	"ru": "Russian",
	"zh": "Simplified Chinese",
	"sk": "Slovak",
	"sl": "Slovenian",
	"es": "Spanish",
	"sv": "Swedish",
	"th": "Thai",
	"tr": "Turkish",
	"vi": "Vietnamese"
};

export const getLocaleFromCountry = {
	'CA': LocaleCode.enUS,
	'EN': LocaleCode.enUS,
	'US': LocaleCode.enUS,
	'CN': LocaleCode.zhCN,
}
