import { LayerDto, LayerType } from '../models/layer/layer.dto';
import merge from 'lodash/merge';
import { plainToClass } from 'class-transformer';
import { CreativeUnitDto } from '../models/creative-unit/creative-unit.dto';

export class LayersUtils {

	/**
	 * Flatten the layers of a creative unit into a single array.
	 */
	public static flattenLayersFromCreativeUnit(obj: CreativeUnitDto | LayerDto): LayerDto[] {
		// Get all layers of an creativeUnit, and sub layers of any groups.  Flatten all into a single array.
		let layers: LayerDto[] = [];

		// Add the layers from the object.
		if (obj.layers) {
			layers = layers.concat(obj.layers);
		}

		// If this is a group, we need to flatten the sub layers.
		for (let layer of layers) {
			if (layer.type === 'group') {
				layers = layers.concat(this.flattenLayersFromCreativeUnit(layer));
			}
		}

		return layers;
	}

	/**
	 * Recursively flatten the layers of an array of layers into a single array.
	 */
	public static flattenLayers(layers: LayerDto[]): LayerDto[] {
		return layers?.reduce<LayerDto[]>((acc, layer) => {
			acc.push(layer);

			if (layer.type === LayerType.GROUP) {
				acc = acc.concat(this.flattenLayers(layer.layers));
			}

			return acc;
		}, []);
	}

	/**
	 * Recursively map all layers of an array of layers with a mapping function.
	 */
	public static mapAllLayers(layers: LayerDto[], func: Function) {
		return (layers || [])?.map(layer => {
			const newLayer = { ...func(layer)};

			if (newLayer.layers) {
				newLayer.layers = this.mapAllLayers(newLayer.layers, func);
			}

			return newLayer;
		});
	}

	/**
	 * Recursively find all layers of an array of layers that match a condition.
	 * NOTE: Returns the list of found arrays, not a boolean.
	 */
	public static findAllLayers(layers: LayerDto[], func: Function): LayerDto[] {
		return (layers || []).reduce<LayerDto[]>((acc, layer) => {
			if (func(layer)) {
				acc.push(layer);
			}

			if (layer.layers) {
				acc = acc.concat(this.findAllLayers(layer.layers, func));
			}

			return acc;
		}, []);
	}

	/**
	 * Recursively filter all layers of an array of layers that match a condition.
	 */
	public static filterAllLayers(layers: LayerDto[], func: Function, flatten?: boolean) {
		let filteredLayers: LayerDto[] = [];

		(layers || []).forEach(layer => {
			// console.log('Filter Checking', layer.label, func(layer));
			if (func(layer)) {
				if (layer.layers) {
					if (flatten) {
						filteredLayers = filteredLayers.concat(this.filterAllLayers(layer.layers, func));
					} else {
						let newLayer = {
							...layer,
							layers: this.filterAllLayers(layer.layers, func)
						};
						filteredLayers.push(newLayer);
					}
				} else {
					filteredLayers.push(layer);
				}
			}

		});

		return filteredLayers;
	}

	/**
	 * Recursively override all of the layers of a package with the layers of a unit.
	 */
	public static overrideLayers(packageLayers: LayerDto[], unitLayers: LayerDto[]): LayerDto[] {
		return this.mapAllLayers(packageLayers, (layer: LayerDto) => {
			const layerOverrides = this.findAllLayers(unitLayers as LayerDto[], (creativeUnitLayer: LayerDto) => creativeUnitLayer.id === layer.id);

			if (layerOverrides.length) {
				// return merge(plainToClass(LayerDto, layerOverrides[0]), JSON.parse(JSON.stringify(layer)));
				return merge(layerOverrides[0], JSON.parse(JSON.stringify(layer)));
			}

			return layer;
		});
	}

	/**
	 * Recursively reduce all layers of an array of layers with a reducing function.
	 */
	public static reduceAllLayers(layers: LayerDto[], func: Function) {
		return layers.reduce((acc, layer) => {
			const newLayer = { ...func(layer)};

			if (newLayer.layers) {
				newLayer.layers = this.reduceAllLayers(newLayer.layers, func);
			}

			return acc.concat(newLayer);
		}, []);
	}

}
