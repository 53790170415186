import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import 'codemirror/mode/sass/sass';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/fold/foldcode';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';
// import { BrowserTracing } from '@sentry/tracing';
// import { Replay } from '@sentry/angular';
import { i18n } from './i18n';

if (environment.production) {
	const serverErrorsRegexArray = [
		/0 Unknown Error/g,
		/403 Forbidden/g,
		/Http failure response/g,
		/500 Internal Server Error/g,
		/401 Unauthorized/g,
		/404 Not Found/g,
		/502 Bad Gateway/g,
		/503 Service Unavailable/g,
		/AbortError/g
	];

	Sentry.init({
		dsn: 'https://3e363d0be42a4cdc82f12647d0417489@o506945.ingest.sentry.io/4504278066266112',

		tracesSampleRate: 0.1,
		replaysSessionSampleRate: 0.5,

		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: ['localhost'],
				routingInstrumentation: Sentry.routingInstrumentation
			})
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		ignoreErrors: serverErrorsRegexArray,
		beforeSend(event, hint) {
			const error = hint.originalException.toString();

			let strInMatches = serverErrorsRegexArray.some(match => error.match(match));

			if (error && error !== '[object Object]' && strInMatches === false) {
				// Sentry.showReportDialog({
				// 	eventId: event.event_id,
				// 	title: 'Something went wrong.',
				// 	subtitle: 'It might be your internet connection. Please tell what you did before this happened.',
				// 	successMessage: 'Your feedback has been sent. Thank you!'
				// });
				return event;
			} else {
				return null;
			}
		}
	});

	enableProdMode();
}
i18n.getTranslationProviders().then((providers) => {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch(err => console.error(err));
})
	.catch(err => {
		console.error(err);
		alert('Error loading translation files. Please try again later.');
	});
