import { AssetDto } from "../../asset/models/asset.dto";
import { isEqual, merge } from 'lodash';
import { ObjectUtils } from '../../_core/utils/utils.object';
import {
	CreativeUnitDimensionsDto,
	CreativeUnitDimensionsUnit,
	CreativeUnitDto,
	CreativeUnitWithCustomizationsDto
} from '../models/creative-unit/creative-unit.dto';
import { CustomizationOptionDto } from '../models/customization/customization-option.dto';
import {
	CustomizationDto,
	CustomizationItemDto,
	CustomizationType,
	getCustomizationClassByType
} from '../models/customization/customization.dto';
import { LayerDto } from '../models/layer/layer.dto';
import { CustomizationUtils } from './customization.utils';
import { LayersUtils } from './layers.utils';
import { Evaluate } from '../../_core/utils/utils.evaluate';
import { MergeTags } from '../../_core/utils/utils.merge-tags';
import { LayerOptimizationsDto } from '../models/layer/layer-optimization.dto';
import { PublicBusiness } from '../../business/business.entity';

import { Dimension, Dimensions as DimensionUtils } from '../../_core/utils/utils.dimensions';
import { MergePropertiesUtils } from './merge-properties.utils';

export class CreativeUnitConversionConfig {
	// The Cloudinary ID of the bucket to use for image optimizations.
	cloudinaryBucketId: string;

	// The url to the VML Transformer engine.
	taskerEngineUrl?: string;

	// Business data to use for merge tags.
	business: PublicBusiness;

	// The method to use to convert SASS to CSS.
	sassConversionMethod: Function;

	// Preview Mode
	previewMode?: boolean;
}

export class CreativeUnitUtils {
	/**
	 * Convert an ad unit with customization to a regular ad unit.
	 * Applies all the customizations to the root and layers.
	 * Then it removes all the customizations data.
	 */
	public static async convertToCreativeUnit(
		unit: CreativeUnitWithCustomizationsDto,
		config: CreativeUnitConversionConfig
	): Promise<CreativeUnitDto> {
		// console.log('Converting to Creative Unit', unit, config);

		let newUnit = { ...unit };

		// Delete the customizations keys on the root and in layers.
		// We have to force the type here because we are removing the customizations keys.
		// that shouldn't be there, but we don't want to delete them because they are read-only.
		let visibleCustomizations = CustomizationUtils.getAllVisibleCustomizations(
			CustomizationUtils.findAllCustomizations(unit.customizations),
			{
				...unit,
				customizations: CustomizationUtils.findAllCustomizations(unit.customizations)
			}
		);

		// console.log('Converted Unit', newUnit, visibleCustomizations, unit);

		// Update all the layers with the customizations active settings.
		newUnit = {
			...this.updateCreativeUnitWithCustomizationsValues(unit, visibleCustomizations)
		};

		// Build a state object of customizations and layers to check against.  Convert the arrays into objects with id as the key.
		let state = MergePropertiesUtils.getMergeableProperties(
			undefined,
			config.business,
			{
				...newUnit,
				customizations: visibleCustomizations
			}
		);
		// console.log('Merge Tags', state);

		// Alter dimensions based on merge state.
		newUnit = {
			...newUnit,
			dimensions: {
				width: Number(MergeTags.applyMergeTagsToString(newUnit?.dimensions?.width?.toString(), state)),
				height: Number(MergeTags.applyMergeTagsToString(newUnit?.dimensions?.height?.toString(), state)),
				ppi: Number(MergeTags.applyMergeTagsToString(newUnit?.dimensions?.ppi?.toString(), state)),
				defaultDisplayUnit: newUnit?.dimensions?.defaultDisplayUnit
			}
		};

		// Update our state with any new dimensions.
		// state = CreativeUnitUtils.getCreativeUnitMergeableProperties(
		// 	{
		// 		...newUnit,
		// 		customizations: visibleCustomizations
		// 	},
		// 	config.business
		// );




		// Only include layers that are visible.
		let newLayers = LayersUtils.filterAllLayers(newUnit.layers, layer => {
			// console.log('Checking Visibility', layer.label, layer.visibilityConditions);

			if (layer.visibilityConditions) {
				let visible = true;

				// If visibilityConditions are not an array, wrap into an array.
				let conditions = layer.visibilityConditions;
				if (!Array.isArray(conditions)) {
					conditions = [conditions];
				}

				// Iterate over the visibility conditions and check if they are valid.
				for (let condition of layer.visibilityConditions) {
					// console.log('Checking visibility conditions', condition, CollectionUtils.isConditionValid(condition, state), layer.visibilityConditions);

					// Apply merge tags to the condition.
					try {
						condition = JSON.parse(MergeTags.applyMergeTagsToString(JSON.stringify(condition), state));
						// console.log('Successful merge tags', condition);
					} catch (e) {
						console.warn('Unable to parse condition merge tags');
					}

					// Evaluate the condition.
					if (!Evaluate.evaluate(condition, state)) {
						visible = false;
						break;
					}
				}
				return visible;
			} else {
				return true;
			}
		});
		// console.log('New Layers', newLayers);

		// Remove extraneous config from the layers.
		newLayers = LayersUtils.mapAllLayers(newLayers, layer => {

			// console.log('Map Layer', layer, state);
			let mergeTaggedLayer;
			try {
				mergeTaggedLayer = JSON.parse(MergeTags.applyMergeTagsToString(JSON.stringify(layer), state));
			} catch (e) {
				console.warn('Unable to parse merge tags', e);
				mergeTaggedLayer = layer;
			}

			let newLayer = {
				...mergeTaggedLayer,
				customizations: undefined,
				visibilityConditions: undefined
			};

			// If the layer has an 'assetPath' property, replace the Asset property of this layer's type.
			if (layer.assetPath) {
				newLayer = {
					...newLayer,
					[layer.type]: {
						assetPath: mergeTaggedLayer.assetPath
					},
					assetPath: undefined
				};
				// console.log('Asset Path', newLayer);
			}

			// Add in image optimizations
			if (layer.optimizations || layer.previewOptimizations) {
				// console.log('Optimizing Layer', layer.optimizations, layer.previewOptimizations, config.previewMode);
				if (config.previewMode) {
					// Apply preview optimizations on top of media optimizations.
					// console.log('Applying Preview Optimizations', layer.previewOptimizations, layer.optimizations);
					// console.log('Merged Optimizations', merge(layer.optimizations, layer.previewOptimizations));
					newLayer = {
						...newLayer,
						optimizations: merge(newLayer.optimizations, newLayer.previewOptimizations)
					};
				}

				newLayer = LayerOptimizationsDto.optimizeLayer(newLayer, config.cloudinaryBucketId, config.taskerEngineUrl);
				// console.log('Optimized Layer', newLayer);
			}

			return newLayer;
		});

		// Try to parse our styles from SASS to CSS.  The parent needs to pass in their own SASS conversion method.
		if (unit.styles) {
			// console.log('Unit Styles', unit.styles);
			if (Array.isArray(unit.styles)) {
				for (let i = 0; i < unit.styles.length; i++) {
					try {
						// console.log('Converting SASS', unit.styles[i]?.styles);
						let style = MergeTags.applyMergeTagsToString(unit.styles[i]?.styles, state);
						style = await config.sassConversionMethod(style);
						if (style?.length) {
							newUnit = {
								...newUnit,
								styles: newUnit.styles + style
							};
						}
					} catch (e) {
						console.warn("Couldn't parse SASS", e);
					}
				}
			} else {
				try {
					// console.log('Converting SASS', unit.styles);
					let style = MergeTags.applyMergeTagsToString(unit.styles, state);
					newUnit = {
						...newUnit,
						styles: await config.sassConversionMethod(style)
					};
				} catch (e) {
					console.warn("Couldn't parse SASS: " + unit.name, e);
				}
			}

			// Check if any customization values have a `styles` property and apply them.
			for (let customization of CustomizationUtils.findAllCustomizations(unit.customizations)) {
				let value = customization.value;
				let styles = value?.['styles'] || value?.['mappings']?.styles;

				// Check for creative units with styles as well and add them to the end of the styles string.
				if (value?.['creativeUnits']) {
					let unitOverrides = value?.['creativeUnits'].find((u: CreativeUnitDto) => u.id === newUnit.id);
					if (unitOverrides?.styles) {
						styles += unitOverrides.styles;
					}
				}

				if (styles) {
					// console.log('Applying Customization Styles', styles);
					try {
						let style = MergeTags.applyMergeTagsToString(styles, state);
						style = await config.sassConversionMethod(style);
						if (style?.length) {
							newUnit = {
								...newUnit,
								styles: newUnit.styles + style
							};
						}
					} catch (e) {
						console.warn("Couldn't parse SASS", e);
					}
				}
			}

			// Remove tab characters and new lines from the styles.
			newUnit = {
				...newUnit,
				styles: newUnit.styles?.replace(/\t/g, '').replace(/\n/g, '')
			};
		}

		// console.log('Converted Unit', newUnit, newUnit.containerClasses, state);

		return {
			...newUnit,
			containerClasses: MergeTags.applyMergeTagsToString(newUnit.containerClasses || '', state),
			customizations: undefined,
			package: undefined,
			layers: newLayers
		} as CreativeUnitDto;
	}

	/**
	 * Get a layer from a creative unit by its ID.
	 */
	public static getLayerFromId(creativeUnit: CreativeUnitDto, layerId: LayerDto['id']) {
		return creativeUnit.layers.find(layer => layer.id === layerId);
	}

	/**
	 * Strip all animations from an ad unit.
	 */
	public static stripAnimationsFromUnit(unit: CreativeUnitDto) {
		const newConfig = {
			...unit,
			layers: unit.layers?.map(layer => this.stripAnimationsFromLayer(layer)),
			animation: undefined
		};

		return newConfig;
	}

	/**
	 * Strip any layers that have the property `hideFromPreview` set to true.
	 */
	public static stripHiddenLayersFromUnit(unit: CreativeUnitDto) {
		const newConfig = {
			...unit,
			layers: LayersUtils.filterAllLayers(unit.layers, layer => layer.hideFromPreview !== true)
		};

		return newConfig;
	}

	/**
	 * Apply preview optimizations to the media layers of a creative unit.
	 */
	public static applyPreviewOptimizations(unit: CreativeUnitDto) {
		return {
			...unit,
			layers: LayersUtils.mapAllLayers(unit.layers, layer => {
				if (layer.previewOptimizations) {
					console.log('Applying Preview Optimizations', layer.previewOptimizations, layer.optimizations);
					console.log('Merged Optimizations', merge(layer.optimizations, layer.previewOptimizations));
					return {
						...layer,
						optimizations: merge(layer.optimizations, layer.previewOptimizations)
					};
				} else {
					return layer;
				}
			})
		};
	}

	/**
	 * Strip all animations from a layer.
	 */
	public static stripAnimationsFromLayer(layer: LayerDto) {
		let newLayer: LayerDto = {
			...layer,
			animation: undefined
		};

		if (layer.type === 'video') {
			newLayer = {
				...newLayer,
				video: {
					...layer.video,
					autoPlay: false,
					loop: false
				}
			} as LayerDto;
		}

		if (layer.type === 'group') {
			newLayer.layers = LayersUtils.mapAllLayers(layer.layers, subLayer => {
				return this.stripAnimationsFromLayer(subLayer);
			});
		}

		return newLayer;
	}

	/**
	 * Update a customization's value in an ad unit.
	 * This DOES NOT update the actual ad unit values, it only updates the customization values.
	 */
	public static updateCustomizationValue(
		creativeUnit: CreativeUnitWithCustomizationsDto,
		customizationId: string,
		value: any,
		mergeTagState: any,
		useCreativeUnitValue?: boolean
	): CreativeUnitWithCustomizationsDto {
		let newUnit: CreativeUnitWithCustomizationsDto = { ...creativeUnit };
		let customization = CustomizationUtils.findAllCustomizations(newUnit.customizations)?.find(c => c.id === customizationId);
		let customizationClass: typeof CustomizationDto;
		let matchedValue; // The value that was matched from the customization.

		// Check if the root layer has the customization.
		console.log('Customization!', customizationId, customization, newUnit.customizations);
		if (customization) {
			customizationClass = getCustomizationClassByType(customization.type);

			if (useCreativeUnitValue) {
				matchedValue = customizationClass?.findMatchedValue(customization, value) ?? null;
			}

			// Test that the value is valid.
			let newValue = matchedValue || value;
			if (newValue?.visibilityConditions) {
				console.log('Value has visibility conditions', newValue, customization, mergeTagState);

				// Evaluate the visibility conditions.
				const evaluation = Evaluate.evaluateMultiple(newValue.visibilityConditions, mergeTagState);

				if (!evaluation) {
					console.log('Value is invalid', newValue);

					// Check if there are any other options that have the same value but are valid.
					if (customization.type === CustomizationType.SELECT) {

						// Get the valid options for this customization.
						let visibleOptions = customization?.options?.filter(option => {
							// Check if the option has visibility conditions.
							if (option.visibilityConditions) {
								// Evaluate the visibility conditions.
								return Evaluate.evaluateMultiple(option.visibilityConditions, mergeTagState);
							} else {
								return true;
							}
						});

						// Now figure out if there are any valid options that have the same value.
						let validOptions = visibleOptions?.filter(option => {
							// Check if the option has the same value.
							// console.log('Checking option', option.value, newValue.value);
							return isEqual(option.value, newValue.value);
						});
						// console.log('Checking other options for valid values', visibleOptions, validOptions);

						if (validOptions?.length) {
							// Cool! Find the first valid option and use that value.
							newValue = validOptions[0];
						} else {
							// If we found no options, let's just not change anything and return.
							return newUnit;
						}
					} else {
						return newUnit;
					}

				}
			}

			// Update the customization to make sure the new values are set.
			newUnit = {
				...newUnit,
				customizations: CustomizationUtils.mapAllCustomizations(newUnit.customizations, c => {
					if (c.id === customizationId) {
						return {
							...c,
							value: newValue
						};
					} else {
						return c;
					}
				}) //arrayUpdate(newUnit.customizations, customizationId, ({ ...customization, value: matchedValue || value } as any))
			};
		}

		return newUnit;
	}

	/**
	 * Updates an ad unit based on the current values of several customizations.
	 * Returns a new ad unit with the updated values.
	 * @param {CreativeUnitWithCustomizations} creativeUnit - The ad unit to update.
	 * @param {CreativeUnitCustomization[]} customizations - The customizations to update.
	 * @return {CreativeUnitWithCustomizations} A new ad unit with the updated values.
	 **/
	public static updateCreativeUnitWithCustomizationsValues(
		creativeUnit: CreativeUnitDto,
		customizations: CustomizationItemDto[]
	): CreativeUnitDto {
		let newUnit: CreativeUnitWithCustomizationsDto = { ...creativeUnit };

		// Iterate through the customizations and update the ad unit.
		// console.log('Updating customizations', customizations);
		customizations.forEach(customization => {
			// console.log('Updating unit with customization', customization.label, customization);
			newUnit = this.updateCreativeUnitWithCustomizationValue(newUnit, customization.id, customization.value);
			// console.log('Updated unit from customization', newUnit);
		});

		return newUnit;
	}

	/**
	 * Updates the values of an ad unit based on a customization value.
	 * Returns a new ad unit with the updated customization.
	 *
	 * @param {CreativeUnitWithCustomizations} creativeUnit - The ad unit to update.
	 * @param {string} customizationId - The ID of the customization to update.
	 * @param {any} value - The new value for the customization.
	 * @param {boolean} [useCreativeUnitValue] - If true, use the value from the ad unit instead of the value passed in.  Useful for updating multiple units.
	 * @return {CreativeUnitWithCustomizations} A new ad unit with the updated customization.
	 */
	public static updateCreativeUnitWithCustomizationValue(
		creativeUnit: CreativeUnitWithCustomizationsDto,
		customizationId: string,
		value: any,
		useCreativeUnitValue?: boolean
	): CreativeUnitWithCustomizationsDto {
		let newUnit: CreativeUnitWithCustomizationsDto = { ...creativeUnit };
		let customization = CustomizationUtils.findAllCustomizations(newUnit.customizations)?.find(c => c.id === customizationId);
		// let customization = newUnit.customizations?.find(c => c.id === customizationId);
		let customizationClass: typeof CustomizationDto;
		let matchedValue; // The value that was matched from the customization.

		// Check if the root layer has the customization.
		if (customization) {
			customizationClass = getCustomizationClassByType(customization.type);

			if (useCreativeUnitValue) {
				matchedValue = customizationClass?.findMatchedValue(customization, value) ?? null;
			}
		}

		// Check if the customization is in a layer and get a reference.
		// DEPRECATED: No more customizations on layers.
		// if (!customization) {
		// 	let layer = LayersUtils.findAllLayers(newUnit.layers, l => l.customizations?.find(c => c.id === customizationId))?.[0];

		// 	if (!layer || !customization) {
		// 		return newUnit;
		// 	}

		// 	customization = layer.customizations?.find(c => c.id === customizationId);
		// }

		if (!customization?.value) {
			console.warn('Customization value not found', customizationId, customization, creativeUnit);
			return newUnit;
		}

		// Get all the applicable layers for the customization and update them.
		let layers = CreativeUnitUtils.getLayersFromCustomization(customization, (newUnit.layers)) || [];
		for (let layer of layers) {
			// Find customization in layer.
			// console.log('Updating Layer', layer.label, layer, customization);

			customizationClass = getCustomizationClassByType(customization.type);

			if (useCreativeUnitValue) {
				matchedValue = customizationClass?.findMatchedValue(customization, value) ?? null;
			}

			// Update the layer based on the customization type.
			layer = customizationClass?.updateLayer(layer, customization, matchedValue || value, newUnit) || layer;
			// console.log('Updated Layer', layer);

			newUnit = {
				...newUnit,
				layers: LayersUtils.mapAllLayers(newUnit.layers, l => (l.id === layer.id ? layer : l))
			};

			// newUnit = {
			// 	...newUnit,
			// 	layers: arrayUpdate(newUnit.layers, layer.id, layer)
			// }
		}

		// console.log('Matched Value', matchedValue, value, creativeUnit);

		// Support updating other layers if the customization value has a `layers` property or the customization has a mappings layers property.
		if (value?.layers || customization?.mappings || value?.creativeUnits?.length) {
			// console.log('Updating Other Layers', value.layers, value.creativeUnits);
			const updateLayers = customizationClass?.updateLayers(newUnit.layers, customization, matchedValue || value, newUnit) || newUnit.layers;

			// console.log('Updated Layers', updateLayers);
			newUnit = {
				...newUnit,
				layers: customizationClass?.updateLayers(newUnit.layers, customization, matchedValue || value, newUnit) || newUnit.layers
			};
		}

		return newUnit;
	}

	/**
	 * Gets all the layers that a customization applies to, supporting layer customizations or root customizations.
	 * @param {CreativeUnitCustomization} customization - The customization to check.
	 * @param {CreativeUnitWithCustomizations} creativeUnit - The ad unit to check.
	 **/
	public static getLayersFromCustomization(customization: CustomizationItemDto, layers: LayerDto[]): LayerDto[] {
		let applicableLayers = [];

		// Check if the customization is in a layer and update it.  ** DEPRECATED **
		let layer = LayersUtils.findAllLayers(layers, l => l.customizations?.find(c => c.id === customization.id))?.[0];
		if (layer) {
			applicableLayers.push(layer);
		}

		// Check if the customization explictly has applicable layers.  Include nested group layers
		if (customization.layerIds) {
			LayersUtils.findAllLayers(layers, l => customization.layerIds?.includes(l.id))?.forEach(l => applicableLayers.push(l));
		}

		// console.log('Applicable Layers', applicableLayers)
		// Check if the customization has specific creative unit overrides and look through all of those for applicable layers.
		// console.log('Checking Creative Units', customization.name, customization.value);
		// if ((customization as CustomizationSelectDto).value?.creativeUnits) {
		// 	(customization as CustomizationSelectDto).value?.creativeUnits.forEach((unit: CreativeUnitDto) => {
		// 		unit.layers?.forEach(l => {
		// 			applicableLayers.push(l);
		// 		});
		// 	});
		// }

		// Filter out any duplicate layers.
		// applicableLayers = applicableLayers.filter((layer, index, self) => self.findIndex(l => l.id === layer.id) === index);

		return applicableLayers;
	}



	/**
	 * Get the most similar creative unit based on the width and height.
	 * It's likely that no creative unit will match the exact dimensions, so we need to find the closest match.
	 * Adjust the aspect ratio weight to determine how much the aspect ratio should be considered.
	 * A weight of 0.5 means that the aspect ratio and resolution are equally important.
	 * A weight of 1 means that the aspect ratio is the only important factor.
	 * A weight of 0 means that the resolution is the only important factor.
	 */
	public static getMostSimilarCreativeUnit(
		creativeUnits: CreativeUnitDto[],
		width: string,
		height: string,
		aspectRatioWeight: number = 1
	) {
		const widthInt = parseInt(width);
		const heightInt = parseInt(height);
		const targetAspectRatio = widthInt / heightInt;
		const targetResolution = widthInt * heightInt;

		if (!creativeUnits.length) return undefined;

		// console.log('Find a similar creative unit', width, height, targetAspectRatio, targetResolution, creativeUnits);
		return creativeUnits.reduce((prev, curr) => {
			const prevAspectRatio = prev.dimensions.width / prev.dimensions.height;
			const currAspectRatio = curr.dimensions.width / curr.dimensions.height;

			const prevResolution = prev.dimensions.width * prev.dimensions.height;
			const currResolution = curr.dimensions.width * curr.dimensions.height;

			const prevAspectRatioDifference = Math.abs(targetAspectRatio - prevAspectRatio);
			const currAspectRatioDifference = Math.abs(targetAspectRatio - currAspectRatio);

			const prevResolutionDifference = Math.abs(targetResolution - prevResolution);
			const currResolutionDifference = Math.abs(targetResolution - currResolution);

			const prevTotalDifference = aspectRatioWeight * prevAspectRatioDifference + (1 - aspectRatioWeight) * prevResolutionDifference;
			const currTotalDifference = aspectRatioWeight * currAspectRatioDifference + (1 - aspectRatioWeight) * currResolutionDifference;

			// console.log('Comparing Creative Units', prev.name, prevAspectRatio, prevAspectRatioDifference, prevTotalDifference, curr.name, currAspectRatio, currAspectRatioDifference, currTotalDifference);

			return prevTotalDifference < currTotalDifference ? prev : curr;
		});
	}

	/**
	 * Convert a creative unit's dimensions to a new unit size.
	 */
	public static convertToNewUnitSize(
		oldDimensions: CreativeUnitWithCustomizationsDto['dimensions'],
		newUnit: CreativeUnitDimensionsUnit
	): CreativeUnitWithCustomizationsDto['dimensions'] {
		let dimensions = {
			...oldDimensions,
			defaultDisplayUnit: newUnit || oldDimensions.defaultDisplayUnit
		};

		let updated = DimensionUtils.convert(
			oldDimensions.width,
			oldDimensions.height,
			this.creativeUnitDimensionsUnitToDimension(oldDimensions.defaultDisplayUnit),
			this.creativeUnitDimensionsUnitToDimension(dimensions.defaultDisplayUnit),
			oldDimensions.ppi
		);

		// console.log('Updated unit size', updated);

		return {
			...dimensions,
			width: updated?.width ? Number(Number(updated.width).toFixed(4)): undefined,
			height: updated?.height ? Number(Number(updated?.height).toFixed(4)): undefined,
			ppi: updated?.originalScale
		};
	}

	public static creativeUnitDimensionsUnitToDimension(unit: CreativeUnitDimensionsUnit | string) {
		switch(unit) {
			case CreativeUnitDimensionsUnit.INCHES:
				return Dimension.Inches;
			case CreativeUnitDimensionsUnit.CENTIMETERS:
				return Dimension.Centimeters;
			case CreativeUnitDimensionsUnit.MILLIMETERS:
				return Dimension.Millimeters;
			case CreativeUnitDimensionsUnit.POINTS:
				return Dimension.Points;
			case CreativeUnitDimensionsUnit.PICAS:
				return Dimension.Picas;
			case CreativeUnitDimensionsUnit.PIXELS:
			default:
				return Dimension.Pixels;
		}
	}

	public static dimensionToCreativeUnitDimensionsUnit(dimension: Dimension) {
		switch(dimension) {
			case Dimension.Inches:
				return CreativeUnitDimensionsUnit.INCHES;
			case Dimension.Centimeters:
				return CreativeUnitDimensionsUnit.CENTIMETERS;
			case Dimension.Millimeters:
				return CreativeUnitDimensionsUnit.MILLIMETERS;
			case Dimension.Points:
				return CreativeUnitDimensionsUnit.POINTS;
			case Dimension.Picas:
				return CreativeUnitDimensionsUnit.PICAS;
			case Dimension.Pixels:
			default:
				return CreativeUnitDimensionsUnit.PIXELS;
		}
	}

}
