import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorySegmentStore } from './story-segment.store';
import { StorySegment } from './story-segment.model';
import { ValidationError } from '../global/global.model';
import { Clip, ClipQuery, ClipService } from '../clip';
import { StorySegmentQuery } from './story-segment.query';
import { StoryQuery } from '../story/story.query';
import { createOverlayUIState } from '../story/story.model';
import { arrayUpdate, arrayUpsert, filterNil } from '@datorama/akita';
import { filter, take } from 'rxjs/operators';
import { Overlay, OverlayField } from '../overlay/overlay.model';
import { VoiceoverStore } from '../voiceover/voiceover.store';

@Injectable({ providedIn: 'root' })
export class StorySegmentService {
	constructor(
		private storySegmentStore: StorySegmentStore,
		private storySegmentQuery: StorySegmentQuery,
		private clipQuery: ClipQuery,
		private storyQuery: StoryQuery,
		private clipService: ClipService,

		private http: HttpClient
	) {}

	set(segments: StorySegment[]) {
		this.storySegmentStore.reset();
		this.storySegmentStore.set(segments);
	}

	reset() {
		this.storySegmentStore.reset();
		this.storySegmentStore.ui.reset();
	}

	setDefaultClips(segments: StorySegment[]) {
		// console.log('insertClips', insertClips);
		// let clips = insertClips;
		segments.forEach((segment, i) => {
			// let clips = insertClips[i];
			// console.log('segment', segment);
			// Get the UI for the segment to check if it has what we need.
			const segmentWithUI = this.storySegmentQuery.getEntityWithUI(segment.id);

			// Set default parameters for overlays if they don't exist.
			if (segmentWithUI.ui?.overlays?.length === 0) {
				// console.log('Setting default overlays for segment', segment);
				this.storySegmentStore.ui.upsert(segment.id, {
					overlays: segment.overlays.map(overlay => ({
						isEnabled: overlay.isEnabled,
						groupingObjectRaw: overlay.groupingObject,
						groupingObject: overlay.groupingObject
					}))
				});
			}

			// Only insert a default clip if the setting is enabled
			// #1669 - insert default event if there is a clip set, to replace clip from previous question
			if (segment.insertByDefault) {
				this.clipQuery
					.selectClipsForSegment(segment.id, this.storyQuery.getActive()?.id)
					.pipe(
						take(1),
						filterNil,
						filter(clips => clips.length > 0)
						//,
						// take(1)
					)
					.subscribe(clips => {
						// ignore custom clip as default
						const firstClip: Clip = clips?.[0];
						const isFirstClipCustom: boolean = this.clipService.isCustomClip(firstClip);

						if (clips.findIndex(c => c.id === segmentWithUI.ui?.activeClip?.id) === -1) {
							if (!isFirstClipCustom) {
								this.storySegmentStore.ui.upsert(segment.id, { activeClip: firstClip });
								this.checkValidation(segment.id);
							}
							if (isFirstClipCustom && clips.length > 1) {
								this.storySegmentStore.ui.upsert(segment.id, { activeClip: clips?.[0] });
								this.checkValidation(segment.id);
							}
						}
					});
			}
		});
	}

	checkValidation(id: StorySegment['id']) {
		const errors: ValidationError[] = [];

		if (this.storySegmentStore.ui.getValue().entities[id]?.activeClip === undefined) {
			errors.push({
				type: 'undefined-active-clip',
				message: 'Please select a clip for this segment.'
			});
		}

		this.storySegmentStore.ui.upsert(id, { errors });
	}

	setActive(id: string) {
		this.storySegmentStore.setActive(id);
		this.checkValidation(id);
	}

	setActiveClip(id: string, clip: Clip) {
		const activeId = this.storySegmentQuery.getActiveId() as string;
		const ui = this.storySegmentQuery.ui.getEntity(activeId);
		const overlay = ui?.overlays.find(o => o.id === id) || createOverlayUIState(id);
		this.storySegmentStore.ui.upsert(id, {
			activeClip: {
				...clip,
				duration: clip.duration || this.storySegmentQuery.getEntity(id).maximumDuration
			},
			...overlay
		});

		this.checkValidation(id);
	}

	updateClip(key: string, value: any) {
		const activeId = this.storySegmentQuery.getActiveId() as string;
		const ui = this.storySegmentQuery.ui.getEntity(activeId);
		const clip: Clip = ui?.activeClip;

		this.storySegmentStore.ui.upsert(activeId, {
			activeClip: {
				...clip,
				[key]: value
			}
		});

		// Validate the segment since we made changes
		// Long way of getting to the active id
		this.checkValidation(activeId);
	}

	updateOverlay(id: Overlay['id'], key: string, value: any) {
		const activeId = this.storySegmentQuery.getActiveId() as string;
		const ui = this.storySegmentQuery.ui.getEntity(activeId);
		const overlay = ui?.overlays.find(o => o.id === id) || createOverlayUIState(id);
		this.storySegmentStore.ui.upsert(activeId, {
			overlays: arrayUpsert(ui.overlays, id, {
				...overlay,
				[key]: value
			})
		});

		// Validate the segment since we made changes
		// Long way of getting to the active id
		this.checkValidation(activeId);
	}

	updateOverlayField(overlayId: Overlay['id'], fieldId: OverlayField['id'], values: Partial<OverlayField>) {
		const activeId = this.storySegmentQuery.getActiveId();
		const ui = this.storySegmentQuery.ui.getEntity(activeId);
		const overlay = ui?.overlays.find(o => o.id === overlayId) || createOverlayUIState(overlayId);

		this.storySegmentStore.ui.upsert(activeId, {
			overlays: arrayUpsert(ui.overlays, overlayId, {
				// ...overlay,
				fields: arrayUpsert(overlay.fields, fieldId, { id: fieldId, ...values })
			})
		});

		// Long way of getting to the active id
		this.checkValidation(this.storySegmentStore.getValue().entities[this.storySegmentStore.getValue().active]?.id);
	}

	updateOverlaySnapshot(segmentId: StorySegment['id'], overlayId: Overlay['id'], snapshot: string) {
		const ui = this.storySegmentQuery.ui.getEntity(segmentId);
		const overlay = ui?.overlays.find(o => o.id === overlayId) || createOverlayUIState(overlayId);

		this.storySegmentStore.ui.upsert(segmentId, {
			overlays: arrayUpsert(ui.overlays, overlayId, {
				...overlay,
				snapshot
			})
		});

		this.checkValidation(segmentId);
	}
}
